import ProcessingButton from 'components/ProcessingButton';
import SimpleModal from 'components/SimpleModal';
import FileSaver from 'file-saver';
import { range } from 'lodash';
import React, { FC, useMemo, useRef, useState } from 'react'
import { Document, Page } from 'react-pdf';
import Translation from 'utils/Language/Translation';
import 'react-pdf/dist/esm/entry.webpack';
import useResizeDetector from 'components/useResizeDetector';
import { useWebsocket } from 'utils/Socket';
import { prompt } from 'utils/Prompts';
import { authToSimpleUser, useAuth } from 'utils/API';
import { FaChromecast } from 'react-icons/fa';

export const PdfPreview: FC<{ 
  pdf: any; 
  setPdf: any; 
}> = ({ pdf, setPdf }) => {
  const socket = useWebsocket()
  const auth = useAuth()
  const [numPages, setNumPages] = useState<number>(null);
  const pdfRef = useRef(pdf)
  if (pdfRef.current !== pdf && pdf) {
    pdfRef.current = pdf
  }
  const pdfToUse = pdfRef.current

  const pdfSource = useMemo(() => `data:application/pdf;base64,${pdfToUse}`, [pdfToUse])
  const { width, ref: sizeRef } = useResizeDetector({
    handleHeight: false,
    handleWidth: true,
    refreshMode: 'debounce',
    refreshRate: 500,
  });

  return (
    <SimpleModal
      fullscreen={true}
      open={!!pdf}
      title={
        <>
          <Translation name="T.misc.pdf" />
          {socket && (
            <>
              {' '}
              <ProcessingButton className='py-0' disabled={!socket} onClick={async () => {
                const target = await prompt(<Translation name='T.hints.deviceIdentification'/>, '')
                if (target) {
                  const deviceName = target.substring('_be_device_identification_'.length)
                  socket('/device_listener/'+deviceName, {
                    what: 'PREVIEW_PDF',
                    who: authToSimpleUser(auth),
                    payload: pdfToUse
                  })
                }
              }}>
                <FaChromecast/>
              </ProcessingButton>
            </>
          )}
        </>
      }
      onClose={() => {
        setPdf(null)
      }}
      buttons={(close) => [
        <ProcessingButton color="info" onClick={async () => {
          const data = Uint8Array.from(
            atob(pdf).split('').map(char => char.charCodeAt(0))
          );  
          const file = new Blob([data], {type: 'application/pdf'});
          FileSaver.saveAs(file, 'test.pdf');
        }} data-testid={'script-pdf-download'}>
          <Translation name="T.misc.download" />
        </ProcessingButton>,
        <ProcessingButton color="secondary" onClick={close} data-testid={'simple-modal-secondary'}>
          <Translation name="T.misc.close" />
        </ProcessingButton>,
      ]}
    >
      <div
        id="testReportPdfContainer"
        ref={sizeRef}
        style={{
          width: '100%',
          height: '100%',
          padding: '10px',
          background: 'gray',
          overflow: 'auto',
        }}
      >
        <Document file={pdfSource} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
          {range(numPages).map((i) => (
            <Page key={i} pageIndex={i} width={width || 100} />
          ))}
        </Document>
      </div>
    </SimpleModal>
  )
}