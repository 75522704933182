import React from 'react'
import { sleep } from "component_utils/utils"
import { PeripheralPrinter } from "utils/PeripheralManager"
import ProcessingButton from 'components/ProcessingButton'
import Translation from 'utils/Language/Translation'
import { addNotification } from 'utils/NotificationManager'

export const DEVICE_INFORMATION_SERVICE = '0000180A-0000-1000-8000-00805F9B34FB'.toLowerCase()
export const BATTERY_SERVICE = '0000180f-0000-1000-8000-00805f9b34fb'.toLowerCase()

const ZPRINTER_SERVICE_UUID = "38EB4A80-C570-11E3-9507-0002A5D5C51B".toLowerCase()                  // Zebra Bluetooth LE Parser Service
const WRITE_TO_ZPRINTER_CHARACTERISTIC_UUID  = "38EB4A82-C570-11E3-9507-0002A5D5C51B".toLowerCase() // Write to printer characteristic

// const READ_FROM_ZPRINTER_CHARACTERISTIC_UUID = "38EB4A81-C570-11E3-9507-0002A5D5C51B".toLowerCase() // Read from printer characteristic
const ZPL_TEST_IMAGE = btoa(`^XA
^FO200,80^A0,40^FDBe-efficient^FS
^FO150,150^BY3^BCN,60,,,,A^FD1234ABC^FS
^FO25,25^GB520,360,2^FS
^XZ`)

const MaintenanceComponent = ({device}: {device: ZebraBle}) => {
  return (
    <>
      <ProcessingButton onClick={async () => {
        await device.print("application-x/zpl", ZPL_TEST_IMAGE)
      }}>
        <Translation name="T.misc.test"/>
      </ProcessingButton>
    </>
  )
}

class ZebraBle implements PeripheralPrinter<ZebraBle> {
  device: BluetoothDevice

  getDriverName = () => 'ZEBRA_BLE'

  connect = async (data: any) => {
    if (data && navigator.bluetooth.getDevices) {
      // https://googlechrome.github.io/samples/web-bluetooth/watch-advertisements-and-connect-async-await.html
      this.device = (await navigator.bluetooth.getDevices()).find(it => it.id === data.id)

      const abortController = new AbortController();
      const advertisementScanner = new Promise((resolve, reject) => {
        const onAdvertisement = () => {
          // Stop watching advertisements to conserve battery life.
          abortController.abort();
          this.device.removeEventListener('advertisementreceived', onAdvertisement)
          clearTimeout(timeout)
          resolve(null)
        }

        const timeout = setTimeout(() => {
          // Stop watching advertisements to conserve battery life.
          abortController.abort();
          this.device.removeEventListener('advertisementreceived', onAdvertisement)
          addNotification('danger', <Translation name="T.errors.peripherals.couldNotConnectToDevice"/>)
          reject()
        }, 5000)
        
        this.device.addEventListener('advertisementreceived', onAdvertisement);
      })

      await this.device.watchAdvertisements({ signal: abortController.signal });
      await advertisementScanner;
      await this.device.gatt.connect()
      console.log("found device with id: ", data.id, this.device)
    } else {
      this.device = await navigator.bluetooth.requestDevice({
        optionalServices: [DEVICE_INFORMATION_SERVICE, BATTERY_SERVICE, ZPRINTER_SERVICE_UUID],
        acceptAllDevices: true
      });
      await this.device.gatt.connect()
      return { id: this.device.id, name: this.device.name }  
    }
  }

  disconnect = async () => {
    this.device?.gatt.disconnect()
    this.device = null
  }

  isConnected = async () => {
    return !!this.device
  }

  print = async (mediatype: string, _payload: string) => { 
    if (!this.device) {
      return
    }
    const payload = atob(_payload)
    const server = await this.device.gatt.connect()
    const printerService = await server.getPrimaryService(ZPRINTER_SERVICE_UUID)
    const writeCharacteristic = await printerService.getCharacteristic(WRITE_TO_ZPRINTER_CHARACTERISTIC_UUID)
  
    // actually write to the printer
    var chunkSize = 300;
    console.log("sending ", payload.length/chunkSize, " chunks")
    for (let i = 0; i < payload.length; i += chunkSize ) {
      let subStr = payload.substring(i, i + chunkSize);
      let buffer = new TextEncoder().encode(subStr);
      await writeCharacteristic.writeValue(buffer);
      await sleep(50)
    }  
  }

  getMaintenanceComponent = () => MaintenanceComponent
}

export default ZebraBle