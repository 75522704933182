import { ISimpleUserDetails, ISimpleClaim, ClaimType } from 'interfaces';
import { Registry } from '.';

export default ({ get, post, destroy }: Registry) => {
  return {
    claims: {
      getAllManageableClaims: () => get<ISimpleClaim<unknown>[]>(`/api/v1/claims/`),
      getManageableClaimsOfType: <T,>(type: ClaimType) => get<ISimpleClaim<T>[]>(`/api/v1/claims/${type}`),
      getMyClaimsOfType: <T,>(type: ClaimType) => get<ISimpleClaim<T>[]>(`/api/v1/claims/${type}/me`),
      getAllClaimsOfType: <T,>(type: ClaimType) => get<ISimpleClaim<T>[]>(`/api/v1/claims/${type}/all`),
      getCandidates: (type: ClaimType): Promise<ISimpleUserDetails[]> => get(`/api/v1/claims/${type}/candidates`),
      moveClaims: (type: ClaimType, claimId: string, toUid: number): Promise<void> =>
        post(`/api/v1/claims/${type}/${claimId}/move/${toUid}`, null),
      flushClaims: (type: ClaimType, claimId: string): Promise<void> => post(`/api/v1/claims/${type}/${claimId}/flush`, null),
      destroyClaims: (type: ClaimType, claimId: string): Promise<void> => destroy(`/api/v1/claims/${type}/${claimId}`),
    },
  };
};
