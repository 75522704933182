import {
  IBinLocation,
  IGoodsSortingRequest,
  IBusinessPartnerReceiptLocation,
  IGoodsReceiptBatchLots,
  IGoodsReceiptHeader,
  IGoodsReceiptHeaderId,
  INotifyReceiptIssue,
  IAutocompleteEntry,
  IAttachment,
  IGoodsReceiptRowProgress,
  ISalesReturnHeader,
  ISalesReturnAttachment,
  IISalesReturnsDao$ICompanion$IAllowedBatchesSerials,
  ISalesReturnRowProgressScannedLine,
  IFinishedGoodsReceipt,
  IFinishedGoodsReceiptRow,
  IIGoodsReceiptDao$ICompanion$IPlannedGoodsReceiptDraft,
} from 'interfaces';
import { Registry } from '.';

export default ({ get, post, destroy }: Registry) => {
  return {
    finishedGoodsReceipts: {
      getGoodsReceipts: (from: Date, to: Date): Promise<IFinishedGoodsReceipt[]> => get(
        `/api/v1/goods_receipt/finished_receipts`,
        { from: from.toISOString(), to: to.toISOString() }
      ),
      getGoodsReceiptLines: (code: string): Promise<IFinishedGoodsReceiptRow[]> => get(`/api/v1/goods_receipt/finished_receipts/${code}/rows`,),
    },

    goodsReceipts: {
      createPlannedGoodsReceipt: (draft: IIGoodsReceiptDao$ICompanion$IPlannedGoodsReceiptDraft): Promise<void> => post('/api/v1/goods_receipt/plannedGoodsReceipt', draft),

      getGoodsReceipts: (): Promise<Array<IGoodsReceiptHeader>> => get(`/api/v1/goods_receipt/`),
      openGoodsReceipt: (id: IGoodsReceiptHeaderId): Promise<string> => post('/api/v1/goods_receipt/open', id),
      batchesAndLotsOnReceipt: (id: IGoodsReceiptHeaderId): Promise<IGoodsReceiptBatchLots[]> =>
        get('/api/v1/goods_receipt/batchLots', id),

      resync: (id: number) => post(`/api/v1/goods_receipt/${id}/resync`, {}),
      getActiveGoodsReceipt: (id: number): Promise<any> =>
        get(`/api/v1/goods_receipt/${id}`),
      saveAttachments: (id: number, attachments: IAttachment[]): Promise<any> =>
        post(`/api/v1/goods_receipt/${id}/attachments`, attachments),
      saveProgress: (id: number, lineId: number, progress: IGoodsReceiptRowProgress): Promise<IGoodsReceiptRowProgress> =>
        post(`/api/v1/goods_receipt/${id}/line/${lineId}/progress`, progress),
      finishGoodsReceipt: (id: string, locationMapping: Record<string, IBinLocation>): Promise<{ code: string }> =>
        post(`/api/v1/goods_receipt/${id}/finish`, locationMapping),
      deleteGoodsReceiptClaim: (id: string): Promise<void> =>
        post(`/api/v1/goods_receipt/${id}/delete`, null),
    },

    goodsSorting: {
      getRequests: (binCode: string): Promise<IGoodsSortingRequest[]> => get(`/api/v1/goods_sorting/lines/${binCode}`),
      getClients: (binCodes: string[]): Promise<IBusinessPartnerReceiptLocation[]> =>
        get(`/api/v1/goods_sorting/receiptsMapping/${binCodes.join(',')}`),

      getMetaDataForItem: (binCode: string, itemCode: String) =>
        get(`/api/v1/goods_sorting/metadata`, { binCode, itemCode }),
      notifyIssuesWithReceipt: (issue: INotifyReceiptIssue) => post('/api/v1/goods_sorting/notifyIssues', issue),

      getNotificationTypes: (): Promise<IAutocompleteEntry[]> => get('/api/v1/goods_sorting/notificationTypes'),
      getNotificationSubjects: (): Promise<IAutocompleteEntry[]> => get('/api/v1/goods_sorting/notificationSubjects'),
    },

    salesGoodsReturns: {
      getOpenDocuments: (): Promise<ISalesReturnHeader[]> => get('/api/v1/sales/returns/'),
      startDocument: (code: string): Promise<{ id: number }> => post(`/api/v1/sales/returns/open/${code}`, {}),

      claim: {
        getClaim: (claimId: any): Promise<ISalesReturnHeader> => get(`/api/v1/sales/returns/claim/${claimId}`),

        getAllowedBatchesSerials: (claimId: any): Promise<{[k: number]: IISalesReturnsDao$ICompanion$IAllowedBatchesSerials}> => get(`/api/v1/sales/returns/claim/${claimId}/allowedBatchesSerials`),

        updateProgress: (claimId: any, scannedLine: ISalesReturnRowProgressScannedLine): Promise<ISalesReturnRowProgressScannedLine> => post(`/api/v1/sales/returns/claim/${claimId}/update_progress_line`, scannedLine),

        flushClaim: (claimId: any, targetLocation: IBinLocation): Promise<void> => post(`/api/v1/sales/returns/claim/${claimId}/flush`, { targetLocation }),
        deleteClaim: (claimId: any): Promise<void> => destroy(`/api/v1/sales/returns/claim/${claimId}`),

        attachments: {
          get: (claimId: any): Promise<ISalesReturnAttachment[]> => get(`/api/v1/sales/returns/claim/${claimId}/attachments`),
          save: (claimId: any, attachment: ISalesReturnAttachment): Promise<ISalesReturnAttachment> => post(`/api/v1/sales/returns/claim/${claimId}/attachments`, attachment),
          delete: (claimId: any, attachmentId: any): Promise<void> => destroy(`/api/v1/sales/returns/claim/${claimId}/attachments/${attachmentId}`),
        }
      }      
    },
  };
};
