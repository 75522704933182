import React, { PropsWithChildren, ReactNode } from 'react';
import SimpleModal from 'components/SimpleModal';
import ProcessingButton from 'components/ProcessingButton';
import Linkable from 'components/Linkable';
import Translation from 'utils/Language/Translation';
import { sleep } from 'component_utils/utils';
import RoleProtected from 'components/RoleProtected';

type IProps = PropsWithChildren<{
  hasLoaded: any;
  inline: boolean;
  copyValue: () => string;
  description: () => ReactNode;
  detailedRender: () => ReactNode;
  dialog: {
    closeBtnText: ReactNode;
    titleText: ReactNode;
    content: () => ReactNode;
  };
}>;

export default ({ hasLoaded, inline, copyValue, description, detailedRender, dialog }: IProps) => {
  if (!hasLoaded) {
    return null;
  } else if (inline) {
    return (
      <div style={{ display: 'inline-block' }}>
        <SimpleModal
          title={dialog.titleText}
          fullscreen={true}
          trigger={(open) => <Linkable onOpen={open}>{description()}</Linkable>}
          buttons={(close) => [
            <RoleProtected roles={['authCopyObjectCodes']}>
              <ProcessingButton
                color='tertiary'
                onClick={async () => { 
                  navigator.clipboard.writeText(copyValue())
                  await sleep(100)
                }}
                key={'copy'}
              >
                <Translation name="T.misc.copy"/>
              </ProcessingButton>
            </RoleProtected>,
            <ProcessingButton
              color={'primary'}
              onClick={close}
              data-testid={'simple-modal-primary'}
              key={'close'}
            >
              {dialog.closeBtnText}
            </ProcessingButton>,
          ]}
        >
          {dialog.content()}
        </SimpleModal>
      </div>
    );
  } else {
    return (
      <div className="flex-container flex-fill-height">
        <div className="flex-fill-height">{detailedRender()}</div>
      </div>
    );
  }
};
