import React, {
  CSSProperties,
  FC,
  forwardRef,
  MouseEvent,
  ReactNode,
  TouchEvent,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import Defaults, { defaultColumnState, defaultColumnProps, FilterComponent } from './defaults';
import { get, max, range, sumBy } from 'lodash';
import * as ReactIs from 'react-is';
import {
  distinct,
  useStable,
  useLocalStorage,
  associate,
  useBreakpointWatcher,
  getFunVarResult,
  MaybePromise,
} from 'component_utils/utils';
import { breakPoints } from 'styling/stylingVars';
import { mapNotNull } from 'component_utils/utils';
import './style.scss';
import VirtualizedList, { VirtualizedListHandle } from './VirtualizedList';
import PropertyList from 'components/property_list';
import { FaInfoCircle } from 'react-icons/fa';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import * as Immutable from 'object-path-immutable'

export type UserFilter<T> = {
  filter: (row: T, level: number, parents: any[]) => boolean;
  level?: number;
  highestLevel?: number;
};
type ValueFun<T> = (x: T, level: number, parents: any[], path: [T, ...any[]]) => string | number;

export interface ColumnState {
  sort: number;
  filter: string;
}

export interface Column<T, EP, SP> {
  Header:
    | string
    | ReactNode
    | FC<
        {
          rows: T[];
          expanded: any;
          isCollapsed: boolean;
        } & EP
      >;
  Footer?:
    | string
    | ReactNode
    | FC<
        {
          rows: T[];
          expanded: any;
          isCollapsed: boolean;
        } & EP
      >;
  Cell?: FC<
    {
      row: T;
      level: number;
      isExpanded: boolean;
      Expander: typeof Defaults.ExpanderComponent;
      parents: any[];
      path: [T, ...any[]];
      value: ValueFun<T>;
      hasChildren: boolean;
      index: number;
      visualIndex: number;
      rowId: string;
    } & EP
  >;
  FilterComponent?: React.FC<{
    column: Column<T, EP, SP>;
    value: string;
    onChange: (s: string) => void;
    onBlur: (s: string) => void;
  }>;
  value?: ValueFun<T>;
  filterValue?: ValueFun<T>;
  sortingValue?: ValueFun<T>;
  id?: string;
  hideAbove?: keyof typeof breakPoints;
  hideBelow?: keyof typeof breakPoints;
  hideBelowExcludeFromExtra?: boolean;

  show?: (args: SP) => boolean;

  level?: number;
  filterPlaceHolder?: string;
  filterChangePostProcess?: (v: string, extraProps: EP) => MaybePromise<string>;
  filterMethod?: (filter: string, value: string | number, row: T, level: number, parents: any[]) => boolean;

  minWidth?: number;
  width?: number;
  maxWidth?: number;
  fixedWidth?: boolean;

  pivot?: boolean;
  frozen?: boolean;
  expander?: boolean;
  expanderAll?: boolean;
  resizable?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  className?: string;
  fullText?: boolean;
}

interface Props<T, EP, SP> {
  data: T[];

  onExpand?: (args: { index: number; row: T; level: number; parents: any[] }) => void;
  onRowClick?: (args: { index: number; row: T; level: number; parents: any[] }) => void;
  userFilter?: UserFilter<T>;

  colShowProps?: SP;
  getExtraProps?: () => EP;

  alwaysExpendToLevel?: number;

  minRowSize?: number;
  minRows?: number;
  className?: string;
  loading?: boolean;
  noDataText?: string;
  loadingText?: string;
}

function normalize(Comp: any, props: any, fallback = Comp) {
  if (ReactIs.isElement(Comp) || typeof Comp === 'string') {
    return Comp;
  } else if (ReactIs.isValidElementType(Comp)) {
    return <Comp {...props} />;
  }
  return fallback;
}

function anyExpanded(expanded: any) {
  return Object.keys(expanded).some((it) => expanded[it]);
}

type FilteredRow<T> = {
  row: T;
  originalRow: T;
  level: number;
  rowId: string;
  children: FilteredRow<T>[];
};

type RenderRow<T> = {
  isPad?: boolean;

  index?: number;
  level?: number;
  rowId?: string;
  parents?: any[];
  path?: any[];
  hasChildren?: boolean;
  rowPath?: string[];
  isExpanded?: boolean;
  row?: T;
};

interface ExpandedRowDetailsData<T, EP, SP> {
  index: number;
  row: T;
  level: number;
  path: [T, ...any[]];
  parents: any[];
  extra: EP;
}

interface GetRowStylesArgs<T, EP> {
  index: number;
  row: T;
  level: number;
  path: [T, ...any[]];
  parents: any[];
  props: EP;
}

interface BeTableCreatorProps<T, EP, SP> {
  tableId: string;
  noBottomPadding?: boolean;
  columns: Column<T, EP, SP>[];
  subRowsKeys?: string[];
  showRowsWithoutChildren?: boolean;
  getRowId?: (args: { index: number; row: T; level: number; parents: any[], path: any[] }) => string | number;
  ExpandedRowDetails?: FC<ExpandedRowDetailsData<T, EP, SP>>;
  getRowStyle?: (args: GetRowStylesArgs<T, EP>) => React.CSSProperties;
  getRowClasses?: (args: GetRowStylesArgs<T, EP>) => string[];
  rowLayout?: Partial<{
    [k in keyof typeof breakPoints]: (string[])[][]
  }>
}

export type BeTableScrollTo<T> = (index: number | ((rows: RenderRow<T>[]) => number)) => void;
export interface BeTableHandle<T> {
  selection: FilteredRow<T>[];
  compiledSelection: T[];
  renderSelection: RenderRow<T>[];
  scrollTo: BeTableScrollTo<T>;
  setExpanded: (f: (rows: RenderRow<T>[]) => [RenderRow<T>, boolean][]) => void;
}

export function beTableAsComponent<T, EP = {}, SP = {}>(props: BeTableCreatorProps<T, EP, SP>) {
  const useTable = createBeTable(props);
  return forwardRef<BeTableHandle<T>, Props<T, EP, SP>>((props, ref) => {
    const { table, selection, compiledSelection, renderSelection, scrollTo, setExpanded } = useTable(props);
    useImperativeHandle<any, BeTableHandle<T>>(
      ref,
      () => ({
        selection,
        compiledSelection,
        renderSelection,
        scrollTo,
        setExpanded,
      }),
      [selection, compiledSelection, renderSelection, scrollTo, setExpanded],
    );
    return <>{table}</>;
  });
}

const tableMap: any = {};
function createBeTable<T, EP = {}, SP = {}>(config: BeTableCreatorProps<T, EP, SP>) {
  // register this table
  if (tableMap[config.tableId]) {
    console.error('Duplicate table id: ', config.tableId);
  }
  tableMap[config.tableId] = config;

  // create the table
  const {
    tableId,
    columns: _columns,
    subRowsKeys = [],
    noBottomPadding,
    getRowId = ({ index }) => index,
    getRowStyle = () => ({}),
    getRowClasses = () => [],
    ExpandedRowDetails = null,
    showRowsWithoutChildren = false,
    rowLayout,
  } = config;
  const hasSubRows = subRowsKeys.length > 0;
  const columns = _columns
    .filter((it) => it)
    .map((it, index) => {
      return {
        FilterComponent,
        id: `${index}`,
        ...it,
      } as Column<T, EP, SP>;
    });

  return function <T>(props: Props<T, EP, SP>): {
    table: ReactNode;
    selection: FilteredRow<T>[];
    compiledSelection: T[];
    renderSelection: RenderRow<T>[];
    scrollTo: (index: number | ((rows: RenderRow<T>[]) => number)) => void;
    setExpanded: (f: (rows: RenderRow<T>[]) => [RenderRow<T>, boolean][]) => void;
  } {
    const {
      data,
      noDataText,
      loading,
      loadingText,
      minRows,
      minRowSize,

      getExtraProps,
      colShowProps,

      userFilter,
      onRowClick,
      onExpand,

      alwaysExpendToLevel,

      NoRowsFoundComponent,
      LoadingComponent,
      ExpanderComponent,
    } = { ...Defaults, ...props };
    const [expanded, setExpanded] = useState<any>({});
    const [currentlyResizing, setCurrentlyResizing] = useState(false);
    const [columnState, setColumnState] = useState<{ [k: string]: Partial<ColumnState> }>({});
    const [columnSize, setColumnSize] = useLocalStorage<{ [k: string]: number }>(tableId, {});
    const list = useRef<VirtualizedListHandle>();

    const [stableColShowProps] = useStable(colShowProps as SP);
    const currentBreakPoint = useBreakpointWatcher();
    const [visibleColumns] = useMemo(() => {
      const ids = columns.map((it) => it.id);
      if (distinct(ids).length !== ids.length) {
        throw Error('Duplicate id in columns');
      }

      let visibleColumns: Column<T, EP, SP>[] = [];
      let extraColumns: Column<T, EP, SP>[] = [];
      columns
        .filter((c) => {
          if (!c.show) {
            return true;
          }
          return c.show(stableColShowProps);
        })
        .forEach((c) => {
          if (c.hideBelow && breakPoints[currentBreakPoint] <= breakPoints[c.hideBelow]) {
            if (!c.hideBelowExcludeFromExtra) {
              extraColumns.push(c as any);
            }
          } else if (c.hideAbove) {
            if (breakPoints[currentBreakPoint] <= breakPoints[c.hideAbove]) {
              visibleColumns.push(c as any);
            }
          } else {
            visibleColumns.push(c as any);
          }
        });

      // check whether we need a row expander
      if (!visibleColumns.some((col) => col.expander) && ExpandedRowDetails) {
        visibleColumns = [
          {
            Header: ({ expanded }) => <ExpanderComponent isExpanded={anyExpanded(expanded)} />,
            Cell: ExpanderComponent as any,
            id: '__be__row_expander',
            width: 35,
            expander: true,
            expanderAll: true,
            resizable: false,
            fixedWidth: true,
            frozen: true,
          },
          ...visibleColumns,
        ];
      } else if (hasSubRows) {
        visibleColumns = [
          {
            Header: ({ expanded }) => <ExpanderComponent isExpanded={anyExpanded(expanded)} />,
            Cell: () => <React.Fragment />,
            id: '__be__row_all_expander',
            width: 35,
            expander: true,
            expanderAll: true,
            resizable: false,
            fixedWidth: true,
            frozen: true,
          },
          ...visibleColumns,
        ];
      }

      // check whether we need to have an info bubble for the extra information
      if (extraColumns.length) {
        visibleColumns = [
          ...visibleColumns,
          {
            Header: <FaInfoCircle />,
            Cell: (props) => {
              const ref = useRef();
              const inner = extraColumns.map((col, i) => {
                if (col.level === undefined || col.level === props.level) {
                  let header = normalize(col.Header, { isCollapsed: true });
                  let rendered = null;
                  if (col.Cell) {
                    rendered = normalize(col.Cell, props);
                  } else if (col.value) {
                    rendered = col.value(props.row, props.level, props.parents, props.path);
                  }

                  return [
                    <b>{header}</b>,
                    <div className={classnames(col.className, col.fullText && 'full-text')}>{rendered}</div>,
                  ];
                }

                return null;
              }).filter(it => it)
              if (inner.length === 0) 
                return null
              return (
                <>
                  <div ref={ref}>
                    <FaInfoCircle size={2} data-prevent-auto-focus="true" />
                  </div>

                  <UncontrolledPopover target={ref} trigger="legacy">
                    <PopoverBody>
                      <PropertyList rows={inner} />
                    </PopoverBody>
                  </UncontrolledPopover>
                </>
              );
            },
            className: 'text-center',
            id: '__be__row_extra_info',
            width: 35,
            expander: false,
            expanderAll: false,
            resizable: false,
            fixedWidth: true,
            frozen: true,
          },
        ];
      }

      // resolve the columns
      const resolveColumn = (col: Column<T, EP, SP>) => {
        const resolved = {
          ...defaultColumnState,
          ...defaultColumnProps,
          ...col,
          ...(columnState[col.id] || {}),
          style: {},
          originalWidth: 0,
        };
        resolved.originalWidth = resolved.width;
        return resolved;
      };
      return [visibleColumns.map(resolveColumn), extraColumns.map(resolveColumn)];

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnState, currentBreakPoint, stableColShowProps]);

    // size does not have an impact on filtering or related logic
    visibleColumns.forEach((col) => {
      let resized = false;
      col.width = col.originalWidth;
      if (columnSize[col.id] !== undefined) {
        col.width = Math.max(columnSize[col.id], 20);
        resized = true;
      }

      if (col.fixedWidth || resized) {
        col.style = {
          width: `${col.width}px`,
          maxWidth: `${col.width}px`,
          minWidth: `${col.width}px`,
          flex: `${col.width} 0 auto`,
        };
      } else {
        col.style = {
          width: `${col.width}px`,
          flex: `${col.width} 0 auto`,
        };
      }
    });

    // Filter the rows
    const [, , filterChanged] = useStable(visibleColumns.filter((it) => it.filterable).map((it) => it.filter));
    const [, , sortChanged] = useStable(visibleColumns.map((it) => it.sort));

    const filteredRows = useMemo(() => {
      // first we filter the data
      const defaultFilter: Column<T, EP, SP>['filterMethod'] = (filter, value) =>
        value.toString().toLowerCase().includes(filter.toLowerCase());
      const activeFilters = visibleColumns
        .filter((it) => it.filter && it.filter.trim())
        .map((it) => ({
          level: it.level,
          filterMethod: it.filterMethod || defaultFilter,
          filter: it.filter,
          value: it.filterValue || it.value || (() => ''),
        }));
      if (userFilter) {
        activeFilters.push({
          level: userFilter.level,
          filterMethod: (a, b, c, d, e) => userFilter.filter(c, d, e),
          filter: '',
          value: () => '',
        });
      }

      // get the deepest filter level
      let highestLevel = max(activeFilters.map((it) => it.level || 0)) || 0;
      if (userFilter && userFilter.highestLevel) {
        highestLevel = Math.max(highestLevel, userFilter.highestLevel);
      }

      const filterFun = (row: any, index: number, level: number = 0, parents: any[] = []): FilteredRow<any> => {
        const newParents = parents.concat(row);
        let hasChildren = true;
        let newRow: FilteredRow<any> = {
          row,
          level,
          rowId: `${getRowId({ index, row, level, parents, path: newParents })}`,
          originalRow: row,
          children: [],
        };
        if (level < subRowsKeys.length) {
          newRow.children = mapNotNull(row[subRowsKeys[level]], (it, index) =>
            filterFun(it, index, level + 1, newParents),
          );
          newRow.row = { ...row, [subRowsKeys[level]]: newRow.children.map((it) => it.row) };
          hasChildren = newRow.children.length > 0 || showRowsWithoutChildren;
        }

        const passedAllFilters =
          level > highestLevel ||
          activeFilters.every((fc) => {
            if (fc.level === undefined || fc.level === level) {
              return fc.filterMethod(fc.filter, fc.value(row, level, parents, newParents as any), row, level, parents);
            }
            return true;
          });
        if (hasChildren && passedAllFilters) {
          return newRow;
        }
        return null;
      };

      const filteredData = mapNotNull(data || [], (row, index) => filterFun(row, index));
      return filteredData;

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, userFilter, filterChanged]);

    const compiledSelection = useMemo(() => {
      return filteredRows.map((it) => it.row) as T[];
    }, [filteredRows]);

    const resolvedRows = useMemo(() => {
      const sortCol = visibleColumns.find((it) => it.sort !== 0);
      if (!sortCol || (sortCol.level && sortCol.level > 0)) {
        return filteredRows;
      }

      const order = sortCol.sort === 1 ? 1 : -1;
      const sorted = [...filteredRows].sort((a, b) => {
        const va = (sortCol.sortingValue || sortCol.value)(a.row, 0, [], [a.row]);
        const vb = (sortCol.sortingValue || sortCol.value)(b.row, 0, [], [b.row]);
        if (va > vb) {
          return order;
        } else if (va < vb) {
          return -order;
        } else {
          return 0;
        }
      });
      return sorted;
    }, [filteredRows, sortChanged]); // eslint-disable-line react-hooks/exhaustive-deps

    const extraProps = getExtraProps();

    // get state information
    type ResolvedColumn = typeof visibleColumns[number];
    const hasFilters = visibleColumns.some((it) => it.filterable);
    const layout = rowLayout?.[currentBreakPoint]
    let layoutColumns: (string[])[] = []
    let compiledLayouts: string[] = []
    if (layout) {
      layoutColumns = layout.map(it => distinct(it.flat()))
      compiledLayouts = layout.map(it =>  "'" + it.map(arr => arr.join(' ')).join("' '") + "'")
    }

    const renderHeader = (resolved: ResolvedColumn, blockLayout?: string) => {
      let resizer = null;
      if (!blockLayout && resolved.resizable) {
        type Event = TouchEvent | MouseEvent;
        const isTouchEvent = (event: unknown, isTouch: boolean): event is React.TouchEvent => isTouch;

        const onResizeStart = (event: Event, isTouch: boolean) => {
          event.stopPropagation();
          event.preventDefault();
          const parentWidth = (event.target as any).parentElement.getBoundingClientRect().width as number;
          let pageX: number;
          if (isTouchEvent(event, isTouch)) {
            pageX = event.changedTouches[0].pageX;
          } else {
            pageX = event.pageX;
          }

          // start resizing
          setCurrentlyResizing(true);
          const resizerData = {
            startX: pageX,
            parentWidth,
          };

          // define the move and end scenario
          const onResizeMoving = (event: any) => {
            event.stopPropagation();
            event.preventDefault();
            const isTouch = event.type === 'touchmove';

            let pageX: number;
            if (isTouchEvent(event, isTouch)) {
              pageX = event.changedTouches[0].pageX;
            } else {
              pageX = event.pageX;
            }

            let newWidth = resizerData.parentWidth + pageX - resizerData.startX;
            if (resolved.minWidth !== undefined) {
              newWidth = Math.max(newWidth, resolved.minWidth);
            }
            if (resolved.maxWidth !== undefined) {
              newWidth = Math.min(newWidth, resolved.maxWidth);
            }

            setColumnSize((oldStates) => {
              const newStates = { ...oldStates };
              newStates[resolved.id] = newWidth;
              return newStates;
            });
          };
          const onResizeEnd = (event: any) => {
            event.stopPropagation();
            event.preventDefault();

            const isTouch = event.type === 'touchend' || event.type === 'touchcancel';
            let endX: number;
            if (isTouchEvent(event, isTouch)) {
              endX = event.changedTouches[0].pageX;
            } else {
              endX = event.pageX;
            }
            if (pageX === endX) {
              setColumnSize((oldStates) => {
                const newStates = { ...oldStates };
                delete newStates[resolved.id];
                return newStates;
              });
            }

            setCurrentlyResizing(false);
            if (isTouch) {
              document.removeEventListener('touchmove', onResizeMoving);
              document.removeEventListener('touchcancel', onResizeEnd);
              document.removeEventListener('touchend', onResizeEnd);
            }
            document.removeEventListener('mousemove', onResizeMoving);
            document.removeEventListener('mouseup', onResizeEnd);
            document.removeEventListener('mouseleave', onResizeEnd);
          };

          // add the listener events
          if (isTouch) {
            document.addEventListener('touchmove', onResizeMoving);
            document.addEventListener('touchcancel', onResizeEnd);
            document.addEventListener('touchend', onResizeEnd);
          } else {
            document.addEventListener('mousemove', onResizeMoving);
            document.addEventListener('mouseup', onResizeEnd);
            document.addEventListener('mouseleave', onResizeEnd);
          }
        };

        resizer = (
          <div
            className="rt-resizer"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onMouseDown={(e) => {
              onResizeStart(e, false);
            }}
            onTouchStart={(e) => {
              onResizeStart(e, true);
            }}
          />
        );
      }

      const isSortableColumn = resolved.sortable && (resolved.value || resolved.sortingValue);
      const sortFunc = isSortableColumn
        ? (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            setColumnState((oldStates) => {
              const newStates = { ...oldStates };
              Object.keys(newStates).forEach((k) => {
                if (k !== resolved.id && newStates[k]) {
                  newStates[k].sort = 0;
                }
              });
              newStates[resolved.id] = {
                ...(oldStates[resolved.id] || {}),
                sort: (resolved.sort + 1) % 3,
              };
              return newStates;
            });
          }
        : null;
      const expandAllFunc = resolved.expanderAll
        ? (e: React.MouseEvent) => {
            e.stopPropagation();
            const open = !anyExpanded(expanded);
            if (open) {
              setExpanded(() => {
                const fillExpanded = (
                  row: FilteredRow<any>,
                  index: number,
                  level: number,
                  parents: number[],
                ): [string, any] => {
                  if (onExpand) {
                    onExpand({ index, row: row.row, level, parents });
                  }

                  const nextLevelParents = parents.concat(row.row);
                  if (level < subRowsKeys.length) {
                    return [row.rowId, associate(row.children, fillExpanded, level + 1, nextLevelParents)];
                  } else {
                    return [row.rowId, {}];
                  }
                };
                const allExpanded = associate(resolvedRows, fillExpanded, 0, []);
                return allExpanded;
              });
            } else {
              setExpanded({});
            }
          }
        : null;


      let style = resolved.style as CSSProperties
      if (blockLayout) {
        style = {
          gridArea: resolved.id,
          borderRight: 'unset',
          border: '1px solid rgba(0, 0, 0, 0.3)'
        }
      }

      return (
        <div
          key={resolved.id}
          data-testid={`betable-header-${resolved.id}`}
          className={classnames(
            'rt-th',
            isSortableColumn && '-cursor-pointer',
            resolved.resizable && 'rt-resizable-header',
            resolved.pivot && 'rt-header-pivot',
            resolved.sort !== 0 ? (resolved.sort === 1 ? '-sort-desc' : '-sort-asc') : '',
          )}
          onClick={sortFunc || expandAllFunc}
          style={style}
          role="columnheader"
        >
          <div className={classnames(resolved.resizable && 'rt-resizable-header-content')}>
            {normalize(resolved.Header, {
              ...extraProps,
              rows: compiledSelection,
              expanded,
            })}
          </div>
          {resizer}

          {blockLayout && makeFilter(resolved, blockLayout)}
        </div>
      );
    };

    const makeFilter = (resolved: ResolvedColumn, blockLayout?: string) => {
      const inner = resolved.filterable &&
          normalize(resolved.FilterComponent, {
            onBlur: async (s: string) => {
              if (resolved.filterChangePostProcess) {
                const processed = await resolved.filterChangePostProcess(s, extraProps)
                setColumnState((oldStates) => {
                  const newStates = { ...oldStates };
                  newStates[resolved.id] = {
                    ...(oldStates[resolved.id] || {}),
                    filter: processed,
                  };
                  return newStates;
                });  
              }
            },
            onChange: (s: string) => {
              setColumnState((oldStates) => {
                const newStates = { ...oldStates };
                newStates[resolved.id] = {
                  ...(oldStates[resolved.id] || {}),
                  filter: s,
                };
                return newStates;
              });
            },
            value: resolved.filter,
            column: resolved,
          })

      if (blockLayout) {
        if (!inner) {
          return null
        }
        return (
          <div onClick={e => {
            e.stopPropagation()
          }}>
            {inner}
          </div>
        )
      }

      return (
        <div
          key={resolved.id}
          data-testid={`betable-filter-${resolved.id}`}
          className={classnames('rt-th')}
          style={resolved.style}
          role="columnheader"
        >
          <div className={classnames(resolved.resizable && 'rt-resizable-header-content')}>
            {inner}
          </div>
        </div>
      );
    };

    const renderHeaders = (blockLayout?: string) => {
      let toRenderColumns = visibleColumns
      if (blockLayout) {
        toRenderColumns = toRenderColumns.filter(it => layoutColumns[0].includes(it.id))
      }

      return (
        <>
          <div className={classnames("rt-thead -header", blockLayout && '-filters')} style={{}}>
            <div className="rt-tr" role="row" style={blockLayout ? {
              display: 'grid',
              gridTemplateAreas: blockLayout,
              gridTemplateColumns: 'repeat('+layout[0][0].length+',  1fr) '
            } : {}}>
              {toRenderColumns.map(it => renderHeader(it, blockLayout))}
            </div>
          </div>

          {hasFilters && !blockLayout && (
            <div className="rt-thead -filters" style={{}}>
              <div className="rt-tr" role="row">
                {toRenderColumns.map(it => makeFilter(it))}
              </div>
            </div>
          )}
        </>
      );
    };

    const renderFooters = (blockLayout?: string) => {
      let toRenderColumns = visibleColumns
      if (blockLayout) {
        toRenderColumns = toRenderColumns.filter(it => layoutColumns[0].includes(it.id))
      }

      if (toRenderColumns.some(it => it.Footer))
      return (
        <div className="rt-thead -header" style={{}}>
          <div className="rt-tr" role="row" style={blockLayout ? {
            display: 'grid',
            gridTemplateAreas: blockLayout,
            gridTemplateColumns: 'repeat('+layout[0][0].length+',  1fr) '
          } : {}}>
            {toRenderColumns.map(column => {
              let style = column.style as CSSProperties
              if (blockLayout) {
                style = {
                  gridArea: column.id,
                  borderRight: 'unset',
                  border: '1px solid rgba(0, 0, 0, 0.3)'
                }
              }              
              return (
                <div
                  key={column.id}
                  data-testid={`betable-footer-${column.id}`}
                  className={classnames('rt-th', column.className)}
                  style={style}
                  role="columnheader"
                >
                  {column.Footer ? normalize(column.Footer, {
                    ...extraProps,
                    rows: compiledSelection,
                    expanded,
                  }): ''}
                </div>
              );                
            })}
          </div>
        </div>
      );
    };

    const makeRow = (rowInfo: RenderRow<any>, visualIndex: number, rowProps: any, blockLayout?: string) => {
      const { index, isPad, level, parents, path, rowId, hasChildren, rowPath, isExpanded, row } = rowInfo;
      let toRenderColumns = visibleColumns
      if (blockLayout) {
        toRenderColumns = toRenderColumns.filter(it => (layoutColumns[level] || layoutColumns[0]).includes(it.id))
      }
      
      if (isPad) {
        return (
          <div
            className={classnames('rt-tr-group', visualIndex % 2 ? '-even' : '-odd')}
            data-row-id={rowId}
            role="rowgroup"
            key={rowId}
            {...rowProps}
          >
            <div className={classnames('rt-tr', '-padRow')} role="row" style={blockLayout ? {
              borderTop: '1em solid black',
              display: 'grid',
              gridTemplateAreas: blockLayout,
              gridTemplateColumns: 'repeat('+(layout[level] || layout[0])[0].length+',  1fr) '
            } : {}}>
              {toRenderColumns.map((col) => {
                let style = col.style as CSSProperties
                if (blockLayout) {
                  style = {
                    gridArea: col.id,
                    borderRight: 'unset',
                    border: '1px solid rgba(0, 0, 0, 0.3)'
                  }
                }
                return (
                  <div className={'rt-td'} role="gridcell" style={style} key={col.id}>
                    <span>&nbsp;</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }

      const hasSubRows = level < subRowsKeys.length;
      const toggleExpanded = (e: any) => {
        e.stopPropagation();
        setExpanded((expanded: any) => {
          let tmp = null 
          if (isExpanded) {
            tmp = Immutable.set(expanded, rowPath, false)
          } else {
            if (onExpand) onExpand({ index, row, level, parents });
            tmp = Immutable.set(expanded, rowPath, {})
          }
          return tmp;
        });
      };

      // actually render the row itself
      let expandedRowDetails = null;
      if (!hasSubRows && isExpanded) {
        expandedRowDetails = (
          <div style={{ paddingLeft: '34px' }}>
            {normalize(ExpandedRowDetails, {
              index,
              row,
              level,
              path,
              parents,
              extra: extraProps as EP,
            })}
          </div>
        );
      }

      return (
        <div
          className={classnames('rt-tr-group', visualIndex % 2 ? '-even' : '-odd')}
          data-testid={`betable-row-${visualIndex}`}
          data-row-id={rowId}
          role="rowgroup"
          key={rowId}
          {...rowProps}
        >
          <div
            className={classnames(
              'rt-tr',
              ...getRowClasses({ index, row, level, parents, path: path as any, props: extraProps as EP }),
            )}
            role="row"
            onClick={() => onRowClick({ index, row, level, parents })}
            style={{
              ...(blockLayout ? {
                borderTop: level === 0 && '1em solid black',
                backgroundColor: `rgba(0,0,0,${level * 0.1})`,
                display: 'grid',
                gridTemplateAreas: blockLayout,
                gridTemplateColumns: 'repeat('+(layout[level] || layout[0])[0].length+',  1fr) '
              } : {}),
              ...getRowStyle({ index, row, level, parents, path: path as any, props: extraProps as EP }),
            }}
          >
            {toRenderColumns.map((col, index) => {
              let onClick = null;
              if (col.expander) {
                onClick = toggleExpanded;
              }

              let content = null;
              if (col.level === undefined || col.level === level) {
                if (col.Cell) {
                  content = normalize(col.Cell, {
                    ...extraProps,
                    row,
                    level,
                    isExpanded,
                    path,
                    parents,
                    hasChildren, 
                    value: col.value,
                    Expander: ExpanderComponent,
                    index,
                    visualIndex,
                    rowId,
                  });
                } else if (col.value) {
                  content = col.value(row, level, parents, path as any);
                }
              }

              let style = col.style as CSSProperties
              if (blockLayout) {
                style = {
                  gridArea: col.id,
                  borderRight: 'unset',
                  border: '1px solid rgba(0, 0, 0, 0.3)'
                }
              }
              return (
                <div
                  className={classnames(
                    'rt-td',
                    col.expander && 'rt-expandable',
                    col.className,
                    col.fullText && 'full-text',
                  )}
                  role="gridcell"
                  onClick={onClick}
                  key={col.id}
                  data-testid={`betable-row-${visualIndex}-${col.id}`}
                  style={style}
                >
                  {content}
                </div>
              );
            })}
          </div>
          {expandedRowDetails}
        </div>
      );
    };

    const renderRows = useMemo(() => {
      const flattenResolved = (
        rows: FilteredRow<any>[],
        level = 0,
        path: string[] = [],
        parents: any[] = [],
      ): RenderRow<T>[] => {
        const hasSubRows = level < subRowsKeys.length;
        return rows.reduce<RenderRow<T>[]>((accumulator, row, index) => {
          const rowId = row.rowId;
          const rowPath = path.concat(rowId);
          const isExpanded = get(expanded, rowPath, false) || level < alwaysExpendToLevel;

          accumulator.push({
            index,
            level,
            parents,
            path: [...parents, row.row],
            rowId: `${tableId}-${rowPath.join('-')}`,
            hasChildren: row.children.length > 0,
            rowPath,
            isExpanded,
            row: row.row,
          });

          // get the subrows if any
          if (isExpanded && hasSubRows) {
            const subRows = flattenResolved(row.children, level + 1, rowPath, parents.concat(row.row));
            accumulator.push(...subRows);
          }

          // serialize this row
          return accumulator;
        }, []);
      };

      const flattened = flattenResolved(resolvedRows);
      flattened.push(
        ...range(Math.max(minRows - flattened.length, 0)).map((it) => ({ isPad: true, rowId: `${tableId}-pad-${it}` })),
      );
      return flattened;
    }, [alwaysExpendToLevel, resolvedRows, expanded, minRows]); 

    const tableWidth = sumBy(visibleColumns, (col) => col.width) + 10;

    const scrollTo = useCallback(
      (index: number | ((rows: RenderRow<T>[]) => number)) => {
        list.current.scrollToIndex(getFunVarResult(index, renderRows));
      },
      [renderRows],
    );

    let table = null;
    if (layout) {
      table = (
        <div
          className={classnames('BeTable', '-striped', '-highlight', 'flex-limit-fill-height', 'blockLayout', noBottomPadding && 'mb-0')}
          data-test-loading={loading ? 'true' : 'false'}
          data-grid-layout="true"
        >
          <div className="rt-table-container">
            <div
              className={classnames('rt-table', currentlyResizing ? 'rt-resizing' : '')}
              role="grid"
              style={{
                width: '100%'
              }}
            >
              {renderHeaders(compiledLayouts[0])}
              <VirtualizedList
                ref={list}
                maxHeight={window.innerHeight}
                overscan={50}
                minRowSize={minRowSize}
                rowRenderer={({ index, props }) => makeRow(renderRows[index], index, props, compiledLayouts[renderRows[index].level] || compiledLayouts[0])}
                padRenderer={({ height }) => (
                  <div
                    className="rt-tr-group"
                    role="rowgroup"
                    style={{
                      width: `100%`,
                      height: `${height}px`,
                      ...(height === 0 ? { display: 'none' } : {}),
                    }}
                  />
                )}
                rowCount={renderRows.length}
                containerProps={{
                  className: classnames('rt-tbody'),
                }}
              />
              {renderFooters(compiledLayouts[0])}
            </div>
          </div>

          {!resolvedRows.length && <NoRowsFoundComponent text={noDataText} />}
          <LoadingComponent loading={loading} text={loadingText} />
        </div>

      )
    } else {
      table = (
        <div
          className={classnames('BeTable', '-striped', '-highlight', 'flex-limit-fill-height', noBottomPadding && 'mb-0')}
          data-test-loading={loading ? 'true' : 'false'}
        >
          <div className="rt-table-container">
            <div
              className={classnames('rt-table', currentlyResizing ? 'rt-resizing' : '')}
              role="grid"
              style={{
                minWidth: tableWidth,
              }}
            >
              {renderHeaders()}
              <VirtualizedList
                ref={list}
                maxHeight={window.innerHeight}
                overscan={50}
                minRowSize={minRowSize}
                rowRenderer={({ index, props }) => makeRow(renderRows[index], index, props)}
                padRenderer={({ height }) => (
                  <div
                    className="rt-tr-group"
                    role="rowgroup"
                    style={{
                      width: `100%`,
                      height: `${height}px`,
                      ...(height === 0 ? { display: 'none' } : {}),
                    }}
                  />
                )}
                rowCount={renderRows.length}
                containerProps={{
                  className: classnames('rt-tbody'),
                }}
              />
              {renderFooters()}
            </div>
          </div>

          {!resolvedRows.length && <NoRowsFoundComponent text={noDataText} />}
          <LoadingComponent loading={loading} text={loadingText} />
        </div>
      )
    }

    // actually render the table
    return {
      table,
      selection: filteredRows,
      compiledSelection,
      renderSelection: renderRows,
      scrollTo,
      setExpanded: useCallback((f: (rows: RenderRow<T>[]) => [RenderRow<T>, boolean][]) => {
        const toExpand = f(renderRows)
        setExpanded((expanded: any) => {
          let tmp = expanded 
          for (const [renderRow, isExpanded] of toExpand) {
            const { index, level, parents, rowPath, row } = renderRow;
            if (!isExpanded) {
              tmp = Immutable.set(tmp, rowPath, false)
            } else {
              if (onExpand) onExpand({ index, row, level, parents });
              tmp = Immutable.set(tmp, rowPath, {})
            }  
          }
          return tmp;
        });
      }, [renderRows, onExpand]),
    };
  };
}

export default createBeTable;
