import {
  IWorkOrder,
  IProductionWorkTime,
  IWorkOrderLine,
  IWorkOrderStep,
  IIProductionDao$ISaveTimeWorkedFlushingError,
  IBinLocation,
  IProductionOrderConfirmation$IProductionConfirmationLine,
  IItem,
  IProductionOrderMaterialRegistration,
  IWorkOrderPlan,
  ICodeVisualCode,
  IProductionResource,
  IWorkOrderResource,
  IIProductionDao$IProductionOrderPlanningUpdate,
} from 'interfaces';
import { Registry } from '.';
import { saveBase64UrlEncode } from 'component_utils/utils';

export type IWorkOrderStepMap = { [k: string]: IWorkOrderStep[] };
export type IWorkOrderResourceMap = { [k: string]: IWorkOrderResource[] };

export default ({ get, post, destroy }: Registry) => {
  return {
    production: {
      createOrder: {
        getWorkOrderPlans: (itemCode: string) => get<IWorkOrderPlan[]>(`/api/v1/production/${saveBase64UrlEncode(itemCode)}/workOrderPlans`),
        createOrder: (
          whsCode: string,
          itemCode: string,
          workOrderPlanCode: string,
          targetQuantity: string,
          linkedSalesOrderCode: string,
          releaseAfterCreate: boolean,
        ) => {
          const params: any = {
            whsCode,
            itemCode,
            workOrderPlanCode,
            targetQuantity,
            releaseAfterCreate,
          }
          if (linkedSalesOrderCode) {
            params.linkedSalesOrderCode = linkedSalesOrderCode
          }
          return post<ICodeVisualCode>(
            '/api/v1/production/createProductionOrder',
            {},
            params
          )
        }
      },

      updatePlanning: (changes: IIProductionDao$IProductionOrderPlanningUpdate[]) => post('/api/v1/production/updatePlanning', changes),

      getWorkOrders: (): Promise<IWorkOrder[]> => get(`/api/v1/production/work_orders`),
      getWorkOrderLines: (code: string): Promise<IWorkOrderLine[]> =>
        get(`/api/v1/production/work_orders/${code}/lines`),
      getWorkOrderSteps: (code: string): Promise<IWorkOrderStep[]> =>
        get(`/api/v1/production/work_orders/${code}/steps`),
      getWorkOrderStepsForMultipleAsMap: (codes: string[]): Promise<IWorkOrderStepMap> =>
        post(`/api/v1/production/work_orders/steps`, codes),
      getWorkOrderResourcesForMultipleAsMap: (codes: string[]): Promise<IWorkOrderResourceMap> =>
        post(`/api/v1/production/work_orders/resources`, codes),
      getResources: (): Promise<IProductionResource[]> => get(`/api/v1/production/resources`),

      getWorkTimes: (): Promise<IProductionWorkTime[]> => get('/api/v1/production/work_confirmation'),
      getAllWorkTimes: (): Promise<IProductionWorkTime[]> => get('/api/v1/production/work_confirmation/all'),
      saveWorkTimes: (entry: IProductionWorkTime): Promise<void> => post('/api/v1/production/work_confirmation', entry),
      deleteWorkTimes: (id: number): Promise<void> => destroy(`/api/v1/production/work_confirmation/${id}`),

      materialRegistration: (code: string, registration: IProductionOrderMaterialRegistration): Promise<void> =>
        post(`/api/v1/production/work_orders/${code}/materials`, registration),
      flushWorkTimes: (ids: number[]): Promise<IIProductionDao$ISaveTimeWorkedFlushingError[]> =>
        post('/api/v1/production/work_confirmation/flush', ids),
      confirmWorkOrder: (
        code: string,
        producedItem: IItem,
        targetLocation: IBinLocation,
        lines: IProductionOrderConfirmation$IProductionConfirmationLine[],
      ): Promise<void> =>
        post(`/api/v1/production/work_orders/${code}/confirm`, {
          targetLocation,
          producedItem,
          lines,
        }),

      getSettingsForMaterialConfirmation: (order: IWorkOrder, step: IWorkOrderStep): Promise<any> =>
        post(`/api/v1/production/settings/materialConfirmationConfiguration`, { order, step }),  

      getSettingsForProductConfirmation: (order: IWorkOrder): Promise<any> =>
        post(`/api/v1/production/settings/productionConfirmationConfiguration`, order),  

      admin: {
        getWorkTimes: (): Promise<IProductionWorkTime[]> => get('/api/v1/production/work_confirmation/all'),
      },
    },
  };
};
