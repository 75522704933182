import React from 'react'
import {
  IItem,
  IItemBinLocation,
  IDefaultBinLocation,
  IIItemDao$ICompanion$IDefaultBinLocationUpdaterInstruction,
  IStockTransferData,
  IAutocompleteEntry,
  IItemMovementHistoryEntry,
  IItemWithoutBarcodeAtLocation,
  IItemLabelPrintJob,
  IIItemDao$ICompanion$IBusinessPartnerSpecificItemPrice,
  IBinLocation,
  ManagedBy,
  ISerialNumber,
  IItemOpenDocuments
} from 'interfaces';
import { EBarcodeFields, IIItemDao$ICompanion$IPriceList, IIItemDao$ICompanion$IPriceListUpdateInstructions, IIItemDao$ICompanion$ISetDefaultBinLocation, IIItemDao$ICompanion$IStockTransferRequest, IIItemDao$ICompanion$ITranslationUpdateInstructions } from 'interfaces/erp-interfaces';
import Translation from 'utils/Language/Translation';
import { addNotification } from 'utils/NotificationManager';
import { Registry } from '.';
import { check, distinctBy, mapAsync, saveBase64UrlEncode } from 'component_utils/utils';
import { confirm } from 'utils/Prompts';

export type AppName =
  | 'BURST'
  | 'GOODS SORTING'
  | 'STOCK TRANSFER'
  | 'REPLENISHMENT'
  | 'KARDEX REPLENISHMENT'
  | 'LOCATION TRANSFER'
  | 'STOCK STORAGE'
  | 'KARDEX STORAGE'
  | 'STOCK PICKING'
  | 'MATERIAL CONFIRMATION'
  | 'PICK AND COLLECT'
  | 'MULTI STOCK TRANSFER';

export default ({ get, post }: Registry) => {
  return {
    item: {
      findItemBy: (args: any): Promise<IItem[]> => post('/api/v1/item/find_by', args),
      updateItem: (code: string, args: any) => post(`/api/v1/item/${saveBase64UrlEncode(code)}/update`, args),

      findItem: (code: string): Promise<IItem> => get(`/api/v1/item/find/${saveBase64UrlEncode(code)}`),
      findMultipleItem: (codes: string[]): Promise<IItem[]> => post(`/api/v1/item/find`, codes),
      getItem: (code: string): Promise<IItem> => get(`/api/v1/item/${saveBase64UrlEncode(code)}`),
      getMultipleItems: (codes: string[]): Promise<IItem[]> => post(`/api/v1/item/get`, codes),
      getItemLocations: (code: string): Promise<Array<IItemBinLocation>> => get(`/api/v1/item/${saveBase64UrlEncode(code)}/bin_locations`),
      getItemsWithoutLocations: (): Promise<IItemWithoutBarcodeAtLocation[]> => get(`/api/v1/item/without_barcodes`),
      getDefaultLocations: (code: string): Promise<IDefaultBinLocation[]> =>
        get(`/api/v1/item/${saveBase64UrlEncode(code)}/default_bin_locations`),
      setDefaultLocation: (
        code: string,
        whsCode: string,
        data: IIItemDao$ICompanion$ISetDefaultBinLocation,
      ): Promise<void> => post(`/api/v1/item/${saveBase64UrlEncode(code)}/default_bin_locations/${saveBase64UrlEncode(whsCode)}`, data),
      setDefaultLocationWithLocationQty: (
        code: string,
        data: IIItemDao$ICompanion$IDefaultBinLocationUpdaterInstruction,
      ): Promise<void> => post(`/api/v1/item/${saveBase64UrlEncode(code)}/default_bin_locations`, data),
      setBarcode: (code: string, field: EBarcodeFields, barcode: string): Promise<void> => post(`/api/v1/item/${saveBase64UrlEncode(code)}/${field}/barcode`, { barcode }),

      setManagedBy: (code: string, managedBy: ManagedBy, newBatchName: string, newSerialPrefix: string) => post(
        `/api/v1/item/${saveBase64UrlEncode(code)}/managedBy/${managedBy}`, { newBatchName, newSerialPrefix }
      ),

      findAutocomplete: (code: string): Promise<IAutocompleteEntry[]> => get(`/api/v1/item/autocomplete/${saveBase64UrlEncode(code)}`),

      getPriceLists: (): Promise<IIItemDao$ICompanion$IPriceList[]> => get(`/api/v1/item/pricelists`),
      getBusinessPartnerPrice: (
        businessPartnerCode: string, 
        itemCode: string,
        quantity?: number,
      ): Promise<IIItemDao$ICompanion$IBusinessPartnerSpecificItemPrice> => get(`/api/v1/item/${saveBase64UrlEncode(itemCode)}/itemPrice/${saveBase64UrlEncode(businessPartnerCode)}/${quantity}`),
      preValidatePriceLists: (data: IIItemDao$ICompanion$IPriceListUpdateInstructions[]): Promise<{[k:string]: string}> => post(`/api/v1/item/pricelists/validate`, data),
      setPriceLists: (data: IIItemDao$ICompanion$IPriceListUpdateInstructions[]): Promise<string[]> => post(`/api/v1/item/pricelists/update`, data),
      setTranslations: (data: IIItemDao$ICompanion$ITranslationUpdateInstructions[]): Promise<string[]> => post(`/api/v1/item/translations/update`, data),

      getOpenDocuments: (code: string): Promise<IItemOpenDocuments[]> => get(`/api/v1/item/${saveBase64UrlEncode(code)}/open_documents`),
      getAllOpenDocuments: (): Promise<IItemOpenDocuments[]> => get(`/api/v1/item/open_documents`),
      getHistory: (code: string): Promise<IItemMovementHistoryEntry[]> => get(`/api/v1/item/${saveBase64UrlEncode(code)}/history`),
      getLastReceiptLocation: (code: string): Promise<IBinLocation | null> => get(`/api/v1/item/${saveBase64UrlEncode(code)}/history/last_receipt_location`),
      
      stockTransfer: async (appName: AppName, data: IStockTransferData[]): Promise<void> => {
        const verified = await post('/api/v1/operations/stock_transfer/validateRequest', data) as IIItemDao$ICompanion$IStockTransferRequest[]
        
        const lineNeedsQc = verified.map(line => line.allocations.some(alloc => {
          if (line.item.managedBy === 'NORMAL') {
            return false
          }
          if (line.item.managedBy === 'BATCH_NUMBER') {
            return !alloc.batch.qualityControlApprovedOrNotRequired
          }
          if (line.item.managedBy === 'SERIAL_NUMBER') {
            return !alloc.serial.qualityControlApprovedOrNotRequired
          }
          return false
        }))
        const hasQcLines = lineNeedsQc.some(it => it)
        const hasPassedQcLines = lineNeedsQc.some(it => !it)
        if (hasQcLines && hasPassedQcLines) {
          throw addNotification('danger', <Translation name="T.errors.canNotMoveQcAndNonQcInSameTransfer"/>)
        }
        if (hasQcLines) {
          // the target locations should be of type qc/bulk or mobile
          const targetLocations = distinctBy(verified.map(it => it.to), it => it.code)
          targetLocations.forEach(
            it => check(
              ['NORMAL'].includes(it.physicalLocationType), 
              () => <Translation name='T.errors.canOnlyMoveBlockForQcToBulkMobileOrQcTypes'/>
            )
          )

          targetLocations.forEach(
            it => check(
              ['BULK', 'MOBILE', 'QC'].includes(it.locationType), 
              () => <Translation name='T.errors.canOnlyMoveBlockForQcToBulkMobileOrQcTypes'/>
            )
          )

          // the target locations should be empty as well
          await mapAsync(targetLocations, async location => {
            const contents = (await get(`/api/v1/bin_location/${location.code}/contents`)) as any[]
            check(contents.length === 0, () => <Translation name="T.errors.canOnlyMoveQcItemsToEmptyLocations"/>)
          })

          // if we move to type qc/bulk we need to lock them
          const qcBulkLocations = targetLocations.filter(it => ['BULK', 'QC'].includes(it.locationType))
          if (
            qcBulkLocations.length > 0 && 
            !await confirm(
              <Translation 
                name="T.warnings.theFollowingLocationsWillBeBlockedDueToQC" 
                params={{ locations: qcBulkLocations.map(it => it.visualCode).join(", ")}}
              />
            )
          ) {
            return
          }

          // move the stock
          await post(`/api/v1/operations/stock_transfer/${appName}`, verified)

          // lock the qc locations
          await Promise.all(qcBulkLocations.map(async location => {
            const code = location.code
            await post(`/api/v1/bin_location/${code}/setBlockStockMovements/true`, { 
              reason: "Block for QC is required before this location can be unlocked"
            })
          }))
        } else {
          // simple transfer without hassle
          await post(`/api/v1/operations/stock_transfer/${appName}`, verified)
        }
      },

      printLabel: (
        printerId: number,
        jobs: IItemLabelPrintJob,
      ): Promise<IItem> =>
        post(`/api/v1/item/label/${printerId}`, jobs),

      printLargeLabel: (
        printerId: number,
        jobs: IItemLabelPrintJob,
      ): Promise<IItem> =>
        post(`/api/v1/item/large_label/${printerId}`, jobs),

      
      serial: {
        updateSerial: (code: string, payload: Partial<ISerialNumber>) => post(`/api/v1/item/serial/${saveBase64UrlEncode(code)}/update`, payload),
      },
      batch: {
        findBatch: (itemCode: string, batchCode: string) => get(`/api/v1/item/batch/find`, { itemCode: saveBase64UrlEncode(itemCode), batchCode: saveBase64UrlEncode(batchCode) }),
        findAutoComplete: (itemCode: string, batchCode: string) => get(`/api/v1/item/batch/autocomplete`, { itemCode: saveBase64UrlEncode(itemCode), batchCode: saveBase64UrlEncode(batchCode) })
      }
    },
  };
};
