import ItemEndpoints from './Item';
import BinLocationEndpoints from './BinLocation';
import GoodsReceiptsEndpoints from './GoodsReceipt';
import ReplenishmentEndpoints from './Replenishment';
import UserEndpoints from './User';
import WallToWallEndpoints from './WallToWall';
import PickListEndpoints from './PickList';
import SystemEndpoints from './System';
import StockStorangeEndpoints from './StockStorage';
import WarehouseEndpoints from './Warehouse';
import RecountingEndpoints from './Recounting';
import KardexEndpoints from './Kardex';
import RestApiEndpoints from './RestApi';
import ClaimsEndpoints from './Claims';
import BusinessPartner from './BusinessPartner';
import Orders from './Orders';
import { Options } from '../API';
import Production from './Production';
import QualityControl from './QualityControl';
import ExactOnline from './ExactOnline';
import StockCorrections from './StockCorrections';

export interface Registry {
  get: <T = any>(url: string, params?: any, options?: Options) => Promise<T>;
  post: <T = any>(url: string, data: any, params?: any, options?: Options) => Promise<T>;
  destroy: <T = any>(url: string, params?: any, options?: Options) => Promise<T>;
}

export default (registry: Registry) => {
  return {
    ...registry,
    ...ItemEndpoints(registry),
    ...BinLocationEndpoints(registry),
    ...GoodsReceiptsEndpoints(registry),
    ...ReplenishmentEndpoints(registry),
    ...UserEndpoints(registry),
    ...WallToWallEndpoints(registry),
    ...PickListEndpoints(registry),
    ...SystemEndpoints(registry),
    ...StockStorangeEndpoints(registry),
    ...WarehouseEndpoints(registry),
    ...RecountingEndpoints(registry),
    ...KardexEndpoints(registry),
    ...RestApiEndpoints(registry),
    ...ClaimsEndpoints(registry),
    ...BusinessPartner(registry),
    ...Orders(registry),
    ...Production(registry),
    ...QualityControl(registry),
    ...ExactOnline(registry),
    ...StockCorrections(registry)
  };
};
