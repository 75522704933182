import SimpleModal from 'components/SimpleModal';
import { IItem } from 'interfaces'
import React, { useCallback, useState } from 'react'
import Translation from 'utils/Language/Translation';
import Item from '.';
import { FloatType, FormInput, FormObject, FormSelect, FormState, FormToggle, Labeled } from 'component_utils/FormUtils';
import { useApi, useApiLoader } from 'utils/API';
import ProcessingButton from 'components/ProcessingButton';
import { addNotification } from 'utils/NotificationManager';

interface Props {
  trigger: any;
  targetQuantity: number;
  warehouseCode?: string;
  salesOrderCode?: string;
  item: IItem;
}

export function ProductionOrderCreator({ trigger, salesOrderCode, targetQuantity, item, warehouseCode }: Props) {
  const [state, setState] = useState<any>({})
  const { data: warehouses, reload: reloadWarehouses } = useApiLoader(
    useCallback(api => api.binLocation.getWarehouses(), []),
    [],
    false
  )
  const { data: plans, reload: reloadPlans } = useApiLoader(
    useCallback(api => api.production.createOrder.getWorkOrderPlans(item.code), [item.code]),
    [],
    false
  )
  const api = useApi()
  return (
    <SimpleModal
      fullscreen
      title={<Translation name="T.objects.item.createProductionOrderTitle" params={{ item: <Item inline obj={item}/> }}/>}
      trigger={trigger}
      onOpen={() => {
        reloadWarehouses()
        reloadPlans()
        setState({ 
          itemCode: item.code, 
          targetQuantity,
          warehouseCode,
          releaseAfterCreate: true,
        })
      }}
      buttons={close => [
        <ProcessingButton color="secondary" onClick={close}>
          <Translation name="T.misc.cancel" />
        </ProcessingButton>,
        <ProcessingButton
          color="primary"
          onClick={async () => {
            const res = await api.production.createOrder.createOrder(
              state.warehouseCode,
              state.itemCode,
              state.workOrderPlanCode,
              state.targetQuantity,
              salesOrderCode,
              state.releaseAfterCreate,
            )
            addNotification('success', <Translation name="T.objects.item.createProductionOrder.createdProdOrder" params={res as any}/>)
            close()
          }}
        >
          <Translation name="T.misc.create"/>
        </ProcessingButton>
      ]}
    >
      <FormState disableFormWrapper state={state} setState={setState}>
        <Labeled title={<Translation name="T.objects.item.createProductionOrder.item"/>}>
          <FormObject<IItem>
            path={`itemCode`}
            autocomplete={api.item.findAutocomplete}
            find={api.item.getItem}
            getCode={useCallback((it) => it.code, [])}
            render={useCallback((it) => <Item inline obj={it} />, [])}
            disabled
          />
        </Labeled>

        <Labeled title={<Translation name="T.objects.item.createProductionOrder.releaseAfterCreate"/>}>
          <FormToggle path='releaseAfterCreate'/>
        </Labeled>

        <Labeled title={<Translation name="T.objects.item.createProductionOrder.warehouseCode"/>}>
          <FormSelect includeEmptyOption path='warehouseCode' options={warehouses.map(it => ({ value: it.code, label: it.visualCode }))}/>
        </Labeled>

        <Labeled title={<Translation name="T.objects.item.createProductionOrder.planCode"/>}>
          <FormSelect includeEmptyOption path='workOrderPlanCode' options={plans.map(it => ({ value: it.code, label: it.visualCode + ' - ' + it.name }))}/>
        </Labeled>

        <Labeled title={<Translation name="T.objects.item.createProductionOrder.targetQty"/>}>
          <FormInput path='targetQuantity' typed={FloatType}/>
        </Labeled>
      </FormState>
    </SimpleModal>
  )
}