import React from 'react'
import { InputPeripheral } from "utils/PeripheralManager"

const MaintenanceComponent = ({device}: {device: ParcelCube}) => {
  return (<></>)
}

// _be_peripheral_input_{"usage":"cartoncube","driverName":"PARCELCUBE","deviceName":"PARCELCUBE","settings":{"host":"127.0.0.1","port":"2442","dimMult":1,"weightMult":1}}
class ParcelCube implements InputPeripheral<ParcelCube> {
  _isConnected: boolean;
  _config: any;

  setConfig(config: any) {
    this._config = config
  }
  getDriverName = () => 'PARCELCUBE'

  connect = async (data: any) => {
    this._isConnected = true;
  }
  disconnect = async () => {
    this._isConnected = false;
  }

  isConnected = async () => {
    return Promise.resolve(this._isConnected)
  }

  getValue = async () => { 
    const response = await window.be_api.proxy.performCall({
      method: "GET",

      scheme: 'http',
      host: this._config.host,
      port: this._config.port,
      uri: "",
      
      headers: {},
      queryParameters: {},
      body: null,
      bodyMediaType: null,
    })
    const parts = (response.body || "").split(";")
    
    const depth = parseFloat(parts[0]) * this._config.dimMult
    const width = parseFloat(parts[1]) * this._config.dimMult
    const height = parseFloat(parts[2]) * this._config.dimMult
    const weight = parseFloat(parts[4]) * this._config.weightMult

    return this._config.v || JSON.stringify({
      width,
      height,
      depth,
      weight
    })
  }

  getMaintenanceComponent = () => MaintenanceComponent
}

(ParcelCube as any)._do_not_register_device = true

export default ParcelCube