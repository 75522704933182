
export interface ICounting {
  allowSimpleConfirmForNextLocation: boolean;
  batchCountingShowItemQuantitiesOnLocation: boolean;
  batchCountingShowItemsOnLocation: boolean;
  countingAllowAnyLocation: boolean;
  countingReasons: string[];
  countingShowItemQuantitiesOnLocation: boolean;
  countingShowItemsOnLocation: boolean;
  countingUnmarkedLocationDefaultFullLocationCounting: boolean;
  guidedCounting: boolean;
  recounterOneShowQuantities: boolean;
  recounterThreeShowQuantities: boolean;
  recounterTwoShowQuantities: boolean;
  wtwEmptyBinLocationItemCode: string;
  wtwStrategy: WallToWallStrategy;
}

export interface IDelivery {
  allowFinishWithoutLabelPrinting: boolean;
  batchDeliveryScriptSource: string;
  deliveryReportContexts: IDelivery$IDeliveryReportContext[];
  enableDeliveryDispatchApplication: boolean;
  packingSlipDefaultStartDateSubtract: number;
  packingSlipDirectSearch: boolean;
  shippingContainers: IDelivery$IShippingContainer[];
  showPrintPreDeliveryDocumentsButtonOnPackAndDelivery: boolean;
  splitPackAndDelivery: boolean;
}

export interface IDelivery$IDeliveryReportContext {
readonly   defaultForDelivery: boolean;
readonly   defaultForDeliveryDispatch: boolean;
  isDefaultForDelivery: boolean;
  isDefaultForDeliveryDispatch: boolean;
  name: string;
}

export interface IDelivery$IShippingContainer {
  itemCode: string;
  type: string;
}

export interface IGoodsReceipt {
  allowOverReceiving: boolean;
  allowQuickFill: boolean;
  enableSelectButtonForGoodsReceipt: boolean;
  goodsSortingOnIssueNotificationType: string;
  includeDrafts: boolean;
  includePurchaseOrders: boolean;
  includeSalesOrders: boolean;
  printLabelsOnReceipt: boolean;
}

export interface IImporters {
  importerScript: string;
  savePurchaseOrderAsDraft: boolean;
  savePurchaseOrderAsNotConfirmed: boolean;
  saveSalesOrderAsNotConfirmed: boolean;
}

export interface IPicking {
  allowClearingPicklistsAtDelivery: boolean;
  alwaysScanTarget: boolean;
  markPicklistPickedAfterPickAndCollect: boolean;
  requireTargets: PickingRequireTargetsMode;
}

export interface IPicklistGenerator {
  alwaysExpandUntilLevel: number;
  auto: IPicklistGenerator$IAuto;
  combineOrderInGroupToSinglePicklist: boolean;
  directSearch: boolean;
  fromBulkAndPickButton: boolean;
  onlyFromPickButton: boolean;
  pushPicklistToKardexOnCreation: boolean;
  searchDefaultDateRangeEndAdd: number;
  searchDefaultDateRangeStartSubtract: number;
  searchDefaultFilter: string;
  useDateFilter: boolean;
}

export interface IPicklistGenerator$IAuto {
  createOnlyIfFullyInPicking: boolean;
  enabled: boolean;
  interval: number;
  maxDateOffset: number;
  minDateOffset: number;
  minimumPrice: number;
  printer: number;
  runFrom: any;
  runTo: any;
}

export interface IProduction {
  materialConfirmationRequireCollectionLocation: boolean;
  materialConfirmationScanOriginalLocationOnFinish: boolean;
}

export interface IStockTransfer {
  allowAlternativeFromLocationReplenishment: boolean;
  groupRequestsBasedOnFromLocationReplenishment: boolean;
  requireScanningReturnLocationAfterReplenishment: boolean;
}

export type PickingRequireTargetsMode = 'NEVER' | 'ONLY_BATCH_PICKING' | 'ALWAYS';
export const PickingRequireTargetsModeValues: PickingRequireTargetsMode[] = ['NEVER' , 'ONLY_BATCH_PICKING' , 'ALWAYS'];

export type WallToWallStrategy = 'SINGLE_COUNTING' | 'DOUBLE_COUNTING_ALL' | 'DOUBLE_COUNTING_ONLY_WRONG';
export const WallToWallStrategyValues: WallToWallStrategy[] = ['SINGLE_COUNTING' , 'DOUBLE_COUNTING_ALL' , 'DOUBLE_COUNTING_ONLY_WRONG'];

