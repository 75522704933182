
export interface IDocumentSpec {
  dpi: number;
  height: number;
  width: number;
}

export interface ILabelElement {
  id: string;
  position: ILabelElementPosition;
  properties: {[key: string] :Object};
  renderIf: string;
  rotation: number;
  size: ILabelElementSize;
  type: LabelElementType;
}

export interface ILabelElementPosition {
  x: number;
  y: number;
}

export interface ILabelElementSize {
  x: number;
  y: number;
}

export interface ILabelInput {
  params: {[key: string] :Object};
  srcType: LabelInputType;
  xlsxFile: string;
}

export interface ILabelSpec {
  document: IDocumentSpec;
  elements: ILabelElement[];
  format: LabelFormat;
  input: ILabelInput;
  pdfGridPageSize: string;
  pdfGridSpec: string;
  sqlQuery: string;
}

export type LabelElementType = 'TEXT' | 'BARCODE' | 'QRCODE' | 'IMAGE' | 'RECTANGLE' | 'CIRCLE';
export const LabelElementTypeValues: LabelElementType[] = ['TEXT' , 'BARCODE' , 'QRCODE' , 'IMAGE' , 'RECTANGLE' , 'CIRCLE'];

export type LabelFormat = 'PDF' | 'PDF_GRID' | 'PNG' | 'ZPL';
export const LabelFormatValues: LabelFormat[] = ['PDF' , 'PDF_GRID' , 'PNG' , 'ZPL'];

export type LabelInputType = 'EMPTY_ROW' | 'SQL' | 'XLSX';
export const LabelInputTypeValues: LabelInputType[] = ['EMPTY_ROW' , 'SQL' , 'XLSX'];

