// polyfills
import 'core-js/stable';

// components
import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApiProvider } from 'utils/API';
import { LanguageProvider } from 'utils/language';
import { NotificationManager } from 'utils/NotificationManager';
import { SocketManager } from 'utils/Socket';
import { ConfigProvider } from 'utils/Config';
import { SpecializationProvider } from 'utils/SpecializationProvider';
import TranslationEditor from 'utils/Language/UpdateTranslation';
import BugReporter from 'utils/BugReporter';
import App from 'App';

// themes
import 'react-datepicker/dist/react-datepicker.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-notifications-component/dist/theme.css';
import 'styling/bootstrap.scss';
import 'styling/grid-layout.scss';
import 'animate.css';
import 'index.css';
import { IconContext } from 'react-icons';
import { PeripheralManager } from 'utils/PeripheralManager';

const AppContainer: FC = () => {
  return (
    <IconContext.Provider value={{ className: 'react-icons' }}>
      <BrowserRouter>
        <BugReporter>
          <ConfigProvider>
            <SpecializationProvider>
              <LanguageProvider>
                <NotificationManager>
                  <ApiProvider>
                    <SocketManager>
                      <TranslationEditor>
                        <PeripheralManager>
                          <App />
                        </PeripheralManager>
                      </TranslationEditor>
                    </SocketManager>
                  </ApiProvider>
                </NotificationManager>
              </LanguageProvider>
            </SpecializationProvider>
          </ConfigProvider>
        </BugReporter>
      </BrowserRouter>
    </IconContext.Provider>
  );
};

export default AppContainer;
