import React, { useContext, useState, useMemo, useCallback } from 'react';
import { Spinner } from 'reactstrap';
import { IConfigDto, IItem, ISerialNumber } from 'interfaces';
import { MaybePromise, useEffectAsync } from 'component_utils/utils';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import FontAwesomeIconButton from 'components/FontAwesomeIconButton';
import logo from 'images/beelogo.png';
import { matchItem } from 'component_utils/objectUtils';
import Translation from './Language/Translation';
import { choiceList } from './Prompts';
import serialSfx from 'audio/serialSound.mp3';
import Item from 'components/objects/item';

export type CompoundBarcode = {
  isRaw?: boolean;
  compoundType?: string;

  itemCode?: string;
  item?: IItem;

  quantity?: number;

  batchCode?: string;
  serialCode?: string;

  mnfDate?: [string, string] | Date;
  expDate?: [string, string] | Date;
}
type UserCompoundItemBarcodeParser = (targetField: keyof CompoundBarcode, s: string) => CompoundBarcode[]

type IPRule = { ip: string, requires2fa: boolean }
interface IConfigContext {
  ipRules: IPRule;
  appVersion: string;
  config: IConfigDto;
  reload: () => void;
}

const defaultCompoundItemBarcodeParser: UserCompoundItemBarcodeParser = (targetField, s) => {
  if (targetField === 'itemCode') {
    return [{ compoundType: 'raw', itemCode: s }]
  } else {
    return []
  }
}
export let compiledUserCompoundItemBarcodeParser: UserCompoundItemBarcodeParser = defaultCompoundItemBarcodeParser
const ConfigContext = React.createContext({} as IConfigContext);

export const compoundItemBarcodeParser = async (
  s: string,
  findAllowedItems: (codes: string[]) => MaybePromise<IItem[]>,
  targetField: keyof CompoundBarcode = 'itemCode',
  findAllowedSerials: (code: string) => MaybePromise<ISerialNumber[]> = () => []
): Promise<CompoundBarcode> => {
  let compounds = compiledUserCompoundItemBarcodeParser(targetField, s)
  if (targetField === 'itemCode') {
    // primary item identification also allows scanning serials
    const serials = await findAllowedSerials(s)
    for (const serial of serials) {
      compounds.push({
        compoundType: 'serial',
        itemCode: serial.itemCode,
        batchCode: serial.lotCode,
        serialCode: serial.visualCode,
      })
    }
  }

  // check which compounds have a valid item associated with the item code/barcode
  const items = await findAllowedItems(compounds.filter(it => it.itemCode).map(it => it.itemCode))
  compounds = compounds.filter(it => items.find(item => matchItem(item, it.itemCode)))

  if (compounds.length === 0) {
    return {
      isRaw: true,
      [targetField]: s
    }
  }

  if (compounds.length === 1) {
    return {
      ...compounds[0],
      item: items.find(item => matchItem(item, compounds[0].itemCode))
    }
  }

  new Audio(serialSfx).play();
  const selected = await choiceList(
    <Translation name="T.errors.barcodeCouldNotBeResolvedToItem" params={{ code: s }}/>,
    compounds.map(it => ({
      value: it,
      label: (
        <Translation 
          name='T.misc.selectCompoundForUse' 
          params={{ ...it, item: <Item inline obj={items.find(item => matchItem(item, it.itemCode))}/> }}
        />
      )
    }))
  )
  if (!selected) {
    throw Error("No selection")
  }
  return {
    ...selected,
    item: items.find(item => matchItem(item, selected.itemCode))
  }
}

export function useConfig() {
  return useContext(ConfigContext).config;
}

export function useConfigReloader() {
  return useContext(ConfigContext).reload;
}

export function useConfigVersion() {
  return useContext(ConfigContext).appVersion;
}

export function useConfigIpRules() {
  return useContext(ConfigContext).ipRules;
}

export const ConfigProvider: React.FC = ({ children }) => {
  const [config, setConfig] = useState<IConfigDto>(null);
  const [appVersion, setAppVersion] = useState<string>(null);
  const [ipRules, setIpRules] = useState<IPRule>(null);
  const [error, setError] = useState(false);

  const loadConfig = useCallback(async () => {
    try {
      const response = await fetch('/api/v1/config/');
      const data = await response.json() as IConfigDto;
      console.log(data);
      try {
        compiledUserCompoundItemBarcodeParser = defaultCompoundItemBarcodeParser;
        if (data.settings.scannerParseItemBarcode) {
          compiledUserCompoundItemBarcodeParser = new Function(`return ${data.settings.scannerParseItemBarcode}`)()(); // eslint-disable-line no-new-func
        }
      } catch (e) {
        console.error(e)
      }

      try {
        const current = document.getElementById("be-custom-css-styles")
        if (current) {
          current.remove()
        }

        if (data.settings.customCssCode) {
          const styleSheet = document.createElement("style")
          styleSheet.id = 'be-custom-css-styles'
          styleSheet.textContent = data.settings.customCssCode
          document.head.appendChild(styleSheet)  
        }
      } catch (e) {
        console.error(e)
      }

      setConfig(data);
    } catch {
      setError(true);
    }
  }, [setConfig, setError]);

  const loadVersion = useCallback(async () => {
    try {
      const response = await fetch('/api/v1/config/version');
      const data = await response.json();
      console.log(`App version: ${data.version}`);
      setAppVersion(data.version);
    } catch {
      setError(true);
    }
  }, [setAppVersion, setError]);

  const loadIpRules = useCallback(async () => {
    try {
      const response = await fetch('/api/v1/config/ipRules');
      const data = await response.json();
      console.log(`Ip rules: ${JSON.stringify(data)}`);
      setIpRules(data);
    } catch {
      setError(true);
    }
  }, [setIpRules, setError]);

  useEffectAsync(async () => {
    await loadIpRules();
    await loadVersion();
    await loadConfig();
    window.addEventListener('online', loadIpRules)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ctx = useMemo<IConfigContext>(() => {
    const reload = async () => {
      await loadIpRules();
      await loadConfig();
    }
    return { config, reload, appVersion, ipRules };
  }, [config, appVersion, ipRules, loadConfig, loadIpRules]);

  const size = '7rem';
  const centeredDivProps: any = {
    style: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  let renderable = null;
  if (error) {
    renderable = (
      <div {...centeredDivProps}>
        <FontAwesomeIconButton
          style={{ width: size, height: size, color: 'red' }}
          icon={faSync}
          onClick={() => {
            window.location.reload();
          }}
        />
      </div>
    );
  } else if (config !== null && appVersion !== null && ipRules !== null) {
    renderable = children;
  } else {
    renderable = (
      <>
        <div {...centeredDivProps}>
          <img src={logo} className="brandLogo" alt="BeEfficient logo" />
        </div>
        <div {...centeredDivProps}>
          <Spinner style={{ width: size, height: size }} animation="grow" color="secondary" />
        </div>
      </>
    );
  }

  return <ConfigContext.Provider value={ctx}>{renderable}</ConfigContext.Provider>;
};
