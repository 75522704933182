import React, { ReactNode, useMemo, useRef, useState } from 'react';
import SubMenu from './submenu';
import { NavItem, NavLink, Nav, Badge } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import { useMenu, isApp, IApp, IAppGroup, useFlatMenu, group } from 'statics/route_data';
import Translation from 'utils/Language/Translation';
import { sumBy } from 'lodash';
import * as Immutable from 'object-path-immutable'
import { IconType } from 'react-icons';
import { useAuth } from 'utils/API';
import { arrayinsert } from 'component_utils/utils';
import { FaRegStar } from 'react-icons/fa';
import { SubApp } from 'App';
import { CgMiniPlayer } from "react-icons/cg";
import { PiHandSwipeRight } from 'react-icons/pi'
import './styles.scss'

function MenuItem(props: {
  padding: string;
  app: IApp;
  onClick: any;
  onOpenAsSubApp: (subApp: SubApp) => any;
  to: string;
  title: ReactNode;
  dataTestId: string;
  icon: IconType;
  notifications: number;
}) {
  const minDistance = 80;
  const containerRef = useRef<HTMLDivElement>(null)

  const swipeStateRef = useRef<any>({})
  function handleSwipeStart() {
    swipeStateRef.current.vibrated = false;
  }

  function handleSwipeMove() {
    // define the minimum distance to trigger the action
    const container = containerRef.current;

    // get the distance the user swiped
    const swipeDistance = container.scrollLeft - container.clientWidth;
    if (swipeDistance < minDistance * -1) {
      if (!swipeStateRef.current.vibrated) {
        swipeStateRef.current.vibrated = true
        navigator.vibrate?.([200, 100, 200]);  
      }
    } else {
      swipeStateRef.current.vibrated = false
    }
  }

  function handleSwipeEnd() {
    // define the minimum distance to trigger the action
    const container = containerRef.current;

    // get the distance the user swiped
    const swipeDistance = container.scrollLeft - container.clientWidth;
    if (swipeDistance < minDistance * -1) {
      props.onOpenAsSubApp({
        title: props.title,
        Component: props.app.component
      })
    }
  }
  
  const allowAsSubApp = props.app.options?.allowAsSubApp
  const linkElement = (
    <NavItem style={{ paddingLeft: props.padding }} className='menu-item-link'>
      <NavLink
        onClick={props.onClick}
        tag={RRNavLink}
        to={props.to}
        activeClassName="active-route"
        data-testid={props.dataTestId}
        className="position-relative"
      >
        <props.icon className="mr-2" />
        {allowAsSubApp && <PiHandSwipeRight/>} {props.title}

        {props.notifications > 0 && (
          <Badge
            color="danger"
            className="position-absolute"
            style={{
              right: '1rem',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            {props.notifications}
          </Badge>
        )}
      </NavLink>
    </NavItem>
  )

  if (!allowAsSubApp) {
    return linkElement
  } 
  return (
    <div 
      ref={containerRef} 
      className={'sub-app-menu-item-container'} 
      onTouchStart={handleSwipeStart}
      onTouchMove={handleSwipeMove}
      onTouchEnd={handleSwipeEnd}
    >
      <div className="menu-item-action menu-item-action-left">
        <CgMiniPlayer className='menu-item-action-left-icon'/>
      </div>
      {linkElement}
    </div>
  );
}

function getNotificationsForItem(item: IApp | IAppGroup, routeNotifications: any): number {
  if (isApp(item)) {
    return routeNotifications[item.path] || 0;
  } else {
    return sumBy(item.children, (child) => getNotificationsForItem(child, routeNotifications));
  }
}

export default ({ onMenuClick, onOpenAsSubApp, routeNotifications }: any) => {
  const menu = useMenu();
  const auth = useAuth()
  const [gOpened, setGOpened] = useState<any>({});

  const renderMenuItem = (item: IApp | IAppGroup, path: string[] = [], depth = 0) => {
    const padding = `${1.5 * depth}rem`;
    const notifications = getNotificationsForItem(item, routeNotifications);
    if (!isApp(item)) {
      const newPath = [...path, item.title]
      const opened = Immutable.get(gOpened, newPath, false)
      return (
        <SubMenu
          padding={padding}
          title={<Translation name={item.title} />}
          icon={item.icon}
          dataTestId={'menu-' + item.title}
          key={item.title}
          notifications={notifications}
          opened={opened}
          setOpened={(opened) => {
            setGOpened((old: any) => {
              if (auth.userSettings.menuOnlyOneOpenAtOnce) {
                return Immutable.set({}, newPath, opened)
              } else {
                return Immutable.set(old, newPath, opened)
              }
            })
          }}
        >
          {item.children.map((child) => renderMenuItem(child, newPath, depth + 1))}
        </SubMenu>
      );
    } else {
      return (
        <MenuItem
          key={item.title}
          padding={padding}
          app={item}
          title={<Translation name={item.title} />}
          to={item.path}
          icon={item.icon}
          onClick={onMenuClick}
          onOpenAsSubApp={onOpenAsSubApp}
          notifications={notifications}
          dataTestId={'menu-item-' + item.title}
        />
      );
    }
  };

  const flatMenu = useFlatMenu();
  const completeMenu = useMemo(() => {
    const favorites = (auth.userSettings.menuFavorites || []).map(it => flatMenu.find(m => m.id === it)).filter(it => it)
    if (favorites.length > 0) {
      return arrayinsert(menu, 1, group(
        'favorites',
        'T.menu.favoritesGroupTitle',
        FaRegStar,
        favorites
      ))
    }
    return menu
  }, [menu, flatMenu, auth])

  return (
    <div className="menu" id="be-main-menu-sidebar">
      <Nav vertical className="list-unstyled">
        {completeMenu.map((item) => renderMenuItem(item))}
      </Nav>
    </div>
  );
};
