import {IModel} from './external-models';

export interface IBinLocation extends  IModel{
  active: boolean;
  barcode: string;
  code: string;
  confirmMovementIfNotEmpty: boolean;
  countingSortingCode: string;
  locationType: LocationType;
  maximalStockLevel: number;
  minimalStockLevel: number;
  movedStockToKardex: string;
  physicalLocationType: PhysicalLocationType;
  pickingStrategy: PickingStrategy;
  preferredOrderCode: string;
  readyForProcessing: boolean;
  requiresCounting: RequiresCounting;
  sortingCode: string;
  stockMovementRestricted: boolean;
  visualCode: string;
  warehouseCode: string;
}

export interface IBinLocationAttributes extends  IModel{
  userAttributes: IBinLocationUserAttribute[];
}

export interface IBinLocationContentSummary extends  IModel{
  binCode: string;
  itemCount: number;
  location: IBinLocation;
  totalItemCount: number;
}

export interface IBinLocationUserAttribute extends  IModel{
  binCode: string;
  name: string;
  value: string;
}

export interface IWarehouse extends  IModel{
  active: boolean;
  city: string;
  code: string;
  country: string;
  name: string;
  street: string;
  streetNo: string;
  visualCode: string;
  zipCode: string;
}

export type LocationType = 'BULK' | 'PICK' | 'MOBILE' | 'RECEIVE' | 'QC' | 'DELIVERY' | 'PRODUCTION' | 'UNKNOWN';
export const LocationTypeValues: LocationType[] = ['BULK' , 'PICK' , 'MOBILE' , 'RECEIVE' , 'QC' , 'DELIVERY' , 'PRODUCTION' , 'UNKNOWN'];

export type PhysicalLocationType = 'NORMAL' | 'KARDEX' | 'DIV' | 'LIQUID_CONTAINER';
export const PhysicalLocationTypeValues: PhysicalLocationType[] = ['NORMAL' , 'KARDEX' , 'DIV' , 'LIQUID_CONTAINER'];

export type PickingStrategy = 'NORMAL' | 'CONFIRM' | 'SKIP' | 'FOCUS';
export const PickingStrategyValues: PickingStrategy[] = ['NORMAL' , 'CONFIRM' , 'SKIP' , 'FOCUS'];

export type RequiresCounting = 'NO' | 'PARTIAL' | 'YES';
export const RequiresCountingValues: RequiresCounting[] = ['NO' , 'PARTIAL' , 'YES'];

