import {IModel} from './external-models';
import {IBinLocation, IWarehouse} from './models-binLocation';

export type BlockForQcType = 'NONE' | 'BATCH' | 'SERIAL';
export const BlockForQcTypeValues: BlockForQcType[] = ['NONE' , 'BATCH' , 'SERIAL'];

export type Direction = 'IN' | 'OUT' | 'NONE';
export const DirectionValues: Direction[] = ['IN' , 'OUT' , 'NONE'];

export interface IBatch extends  IModel{
  admissionDate: any;
  code: string;
  expirationDate: any;
  itemCode: string;
  manufacturingDate: any;
  mnfBatchCode: string;
  qualityControlApprovedOrNotRequired: boolean;
  visualCode: string;
}

export interface IDefaultBinLocation extends  IModel{
  binLocation: IBinLocation;
  enforced: boolean;
  maxStock: number;
  minStock: number;
  whs: IWarehouse;
}

export interface IItem extends  IModel{
  active: boolean;
  avgPrice: number;
  barcode: string;
  barcode2: string;
  barcode3: string;
readonly   batch: boolean;
  blockForQcType: BlockForQcType;
  code: string;
  groupName: string;
readonly   hasBatch: boolean;
  imagePath: string;
  isProductionItem: boolean;
  isSalesBomItem: boolean;
  isStockItem: boolean;
  managedBy: ManagedBy;
  mlToMg: number;
  name: string;
readonly   normal: boolean;
  pdfPath: string;
  productionIssueMethod: ProductionIssueMethod;
  productionItem: boolean;
  productionMaterialToleranceType: ToleranceType;
  productionMaterialToleranceValue: number;
  receiptScanPreferences: IItem$IReceiptPreferences;
  salesBomItem: boolean;
  secondName: string;
readonly   serial: boolean;
  serialManagedByDispatch: boolean;
  stockItem: boolean;
  units: string;
  visualCode: string;
  weightInMg: number;
}

export interface IItem$IReceiptPreferences extends  IModel{
  scanExpirationDate: boolean;
  scanManufacturingBatch: boolean;
  scanManufacturingDate: boolean;
  scanManufacturingSerial: boolean;
}

export interface IItemBinLocation extends  IModel{
  batch: IBatch;
  binCode: string;
  binLocation: IBinLocation;
  freeToMoveQty: number;
  item: IItem;
  itemCode: string;
  onHandQty: number;
  pickQty: number;
  releasedQty: number;
  serialNumber: ISerialNumber;
}

export interface IItemMovementHistoryEntry extends  IModel{
  at: any;
  batch: IBatch;
  bin: IBinLocation;
  binCode: string;
  documentCode: string;
  item: IItem;
  itemCode: string;
  quantity: number;
  serialBatchCode: string;
  serialNumber: ISerialNumber;
  transactionType: TransactionType;
  username: string;
}

export interface IItemOpenDocuments extends  IModel{
  businessPartnerCode: string;
  businessPartnerName: string;
  businessPartnerVisualCode: string;
  code: string;
  direction: Direction;
  itemcode: string;
  lineNum: number;
  openQuantity: number;
  quantity: number;
  shipDate: any;
  type: string;
  visualCode: string;
}

export interface IItemTranslation extends  IModel{
  hasTranslation: boolean;
  itemCode: string;
  languageKey: string;
  translation: string;
}

export interface IItemWithoutBarcodeAtLocation extends  IModel{
  binCode: string;
  binLocation: IBinLocation;
  item: IItem;
  itemcode: string;
}

export interface ISerialNumber extends  IModel{
  admissionDate: any;
  code: string;
  expirationDate: any;
  internalSerialCode: string;
  itemCode: string;
  lotCode: string;
  manufacturingDate: any;
  mnfLotCode: string;
  mnfSerialCode: string;
  qualityControlApprovedOrNotRequired: boolean;
  visualCode: string;
}

export type ManagedBy = 'SERIAL_NUMBER' | 'BATCH_NUMBER' | 'NORMAL';
export const ManagedByValues: ManagedBy[] = ['SERIAL_NUMBER' , 'BATCH_NUMBER' , 'NORMAL'];

export type ProductionIssueMethod = 'BACKFLUSH' | 'MANUAL';
export const ProductionIssueMethodValues: ProductionIssueMethod[] = ['BACKFLUSH' , 'MANUAL'];

export type ToleranceType = 'NONE' | 'PERCENTAGE' | 'QUANTITY';
export const ToleranceTypeValues: ToleranceType[] = ['NONE' , 'PERCENTAGE' , 'QUANTITY'];

export type TransactionType = 'StockTransfer' | 'GoodsReceipt' | 'GoodsDelivery' | 'InventoryPosting' | 'Unknown';
export const TransactionTypeValues: TransactionType[] = ['StockTransfer' , 'GoodsReceipt' , 'GoodsDelivery' , 'InventoryPosting' , 'Unknown'];

