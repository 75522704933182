import { IAddress, IAddressVerdict, IAutocompleteEntry, ICheckableAddress, IHint, IHintRequest, IIOrderDao$IOrderTemplate, IOrderHeader, IOrderLine } from 'interfaces';
import { Registry } from '.';

export default ({ get, post, destroy }: Registry) => {
  return {
    order: {
      createPurchaseOrder: (template: IIOrderDao$IOrderTemplate) => post('/api/v1/purchaseOrder', template),
      createPurchaseOrderDraft: (template: IIOrderDao$IOrderTemplate) => post('/api/v1/purchaseOrderDraft', template),
      createSalesOrder: (template: IIOrderDao$IOrderTemplate) => post('/api/v1/order', template),
    
      printOrderDocument: (code: string, printerId: number): Promise<void> => post(`/api/v1/order/${code}/print/${printerId}`, {}),
      getOrders: (): Promise<IOrderHeader[]> => get('/api/v1/order'),
      getOrder: (code: string): Promise<IOrderHeader> => get(`/api/v1/order/${code}`),
      getOrderLines: (code: string): Promise<IOrderLine> => get(`/api/v1/order/${code}/lines`),
      getOrderAutoComplete: (code: string): Promise<IAutocompleteEntry[]> => get(`/api/v1/order/autocomplete/${code}`),

      pickAndCollect: {
        getOrders: (): Promise<IOrderHeader[]> => get('/api/v1/pick_and_collect/order'),
        getHints: (requests: IHintRequest[]): Promise<IHint[]> => post('/api/v1/pick_and_collect/hints', requests)
      },

      address: {
        getRequiresValidation: (): Promise<ICheckableAddress[]> => get('/api/v1/address/requiresValidation'),
        checkAddress: (type: string, code: string, address: IAddress): Promise<IAddressVerdict> => post(`/api/v1/address/${type}/${code}/addressCheck`, address),
        setAddress: (type: string, code: string, address: IAddress): Promise<any> => post(`/api/v1/address/${type}/${code}/address`, address),
        setValidationVerdict: (type: string, code: string, verdict: boolean): Promise<any> => post(`/api/v1/address/${type}/${code}/address/verdict/${verdict}`, null),
      },
    },
  };
};
