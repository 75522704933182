import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProcessingButton from 'components/ProcessingButton';
import SimpleModal from 'components/SimpleModal';
import React, { FC, Fragment } from 'react';
import { Container } from 'reactstrap';
import EditorComponent from 'routes/tools/translationManager/EditorComponent';
import { useTranslationEditorContext } from 'utils/language';
import Translation from './Translation';

const Modal = () => {
  const { languages, translationKey, setTranslationKey } = useTranslationEditorContext();

  return (
    <SimpleModal
      open={translationKey !== null}
      title={<Translation name="T.misc.translationEditor" />}
      fullscreen
      onClose={() => setTranslationKey(null)}
      buttons={[
        <ProcessingButton
          style={{
            flex: 1,
          }}
          color="primary"
          onClick={() => setTranslationKey(null)}
          data-testid={'translation-editor-close'}
        >
          <FontAwesomeIcon icon={faCheckCircle} />
        </ProcessingButton>,
      ]}
    >
      <Container fluid>
        <b>
          <Translation name="T.misc.Identifier" />:
        </b>
        <br />
        {translationKey?.key}
        <br />
        <br />

        <b>
          <Translation name="T.misc.arguments" />:
        </b>
        <br />
        {translationKey?.args?.join?.(', ')}
        <br />
        <br />

        {languages.map((language) => {
          return (
            <Fragment key={language}>
              <br />
              <b>{language}:</b>
              <br />
              <EditorComponent language={language} row={translationKey?.key} />
            </Fragment>
          );
        })}
      </Container>
    </SimpleModal>
  );
};

const Wrapper: FC = ({ children }) => {
  return (
    <>
      <Modal />
      {children}
    </>
  );
};
export default Wrapper;
