import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Alert } from 'reactstrap';
import { useFormState } from 'component_utils/form_utils';
import { useApi } from 'utils/API';
import { useConfig, useConfigIpRules } from 'utils/Config';
import Translation from 'utils/Language/Translation';
import classNames from 'classnames';
import QRCode from "react-qr-code";
import './style.css';
import LoginScreenSocket, { LoginScreenSocketRef } from 'utils/LoginScreenSocket';
import { ScannerFocusTrigger } from 'components/Scanner';

export default ({ show, deviceName }: { show: boolean; deviceName: string; }) => {
  const api = useApi();
  const config = useConfig();
  const ipRules = useConfigIpRules()
  const [error, setError] = useState<string>(null);
  const [busy, setBusy] = useState(false);
  const [unauthedSocketConnected, setUnauthedSocketConnected] = useState(false)
  const loginScreenSocketRef = useRef<LoginScreenSocketRef>()
  const { state, setState, register, FormInput } = useFormState({
    username: '',
    password: '',
    mfaToken: ''
  });

  const login = async (e: any) => {
    e.preventDefault();

    if (!state.username) {
      document.getElementById('login-username').focus();
      return;
    }

    if (!state.password && !config.settings.allowEmptyPassword) {
      document.getElementById('login-password').focus();
      return;
    }

    // try to login
    try {
      setBusy(true);
      setError(null);
      await api.login(state.username, state.password, state.mfaToken);
      setState({
        username: '',
        password: '',
        mfaToken: '',
      });
    } catch (ex) {
      setError(ex?.response?.data?.message || "unknown error");
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    const handler = (event: any) => {
      const el = document.getElementById('login-username') as HTMLInputElement;
      if (!el) {
        return;
      }

      if (['INPUT', 'SELECT', 'TEXTAREA'].includes(event.target.tagName.toUpperCase())) {
        return;
      }

      if (el.value) {
        return
      }

      el.focus()
    }

    document.addEventListener('click', handler, false);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, [show])

  return (
    <>
      {show && (
        <LoginScreenSocket 
          ref={loginScreenSocketRef}
          deviceName={deviceName} 
          setUnauthedSocketConnected={setUnauthedSocketConnected}
        />
      )}
      <Modal isOpen={show} autoFocus={false}>
        <ScannerFocusTrigger/>
        <Form onSubmit={login}>
          <ModalHeader className='login-header'>
            <Translation name="T.login.header" />
            <div
              style={{
                height: '1rem',
                width: '1rem'
              }}
              className={classNames('d-inline-block', 'dot', unauthedSocketConnected && 'green-dot', !unauthedSocketConnected && 'red-dot')}
            />
          </ModalHeader>
          <ModalBody>
            <Alert color="danger" data-testid={'login-error-alert'} isOpen={Boolean(error)}>
              <Translation name={error} />
            </Alert>

            <div className='d-flex flex-row align-items-center'>
              <div className='flex-grow-1'>
                <FormInput
                  register={register}
                  path="username"
                  title={<Translation name="T.login.username" />}
                  inputProps={{ 
                    autoFocus:true,
                    id: 'login-username',
                    onKeyDown: e => {
                      if (e.key === 'Enter' && !e.repeat) {
                        const target = e.target as HTMLInputElement
                        const inputValue = target.value;
                        if (inputValue.startsWith('_be_device_identification_')) {
                          const deviceName = inputValue.substring('_be_device_identification_'.length)
                          loginScreenSocketRef.current.onRequestDeviceSession(deviceName)
                          target.value = '';
                          return;
                        }  
                      }
                    }
                  }}
                />
                <FormInput
                  register={register}
                  path="password"
                  title={<Translation name="T.login.password" />}
                  inputProps={{ id: 'login-password' }}
                  type="password"
                />
                {ipRules.requires2fa && (
                  <FormInput
                    register={register}
                    path="mfaToken"
                    title={<Translation name="T.login.mfaToken" />}
                    inputProps={{ id: 'login-token' }}
                  />
                )}
              </div>
              {deviceName && (
                <div className='ml-2 text-center' style={{ maxWidth: '90px' }}>
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={'_be_device_identification_' + deviceName}
                    viewBox={`0 0 256 256`}
                  />
                  {deviceName}
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" data-testid="login-button" block disabled={busy}>
              <Translation name="T.login.doLogin" />
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};
