
export type AlertGeneratorType = 'QUERY' | 'SCRIPT';
export const AlertGeneratorTypeValues: AlertGeneratorType[] = ['QUERY' , 'SCRIPT'];

export interface IAlertDefinition {
  createdAt: any;
  cronExpression: string;
  description: string;
  generationSettings: IAlertDefinition$IAlertGenerationSettings;
  group: string;
  id: number;
  name: string;
  outputSettings: IAlertDefinition$IAlertOutputSettings;
  parameters: IAlertDefinition$IAlertParameter[];
  updatedAt: any;
}

export interface IAlertDefinition$IAlertGenerationSettings {
  query: string;
  scriptSource: string;
  type: AlertGeneratorType;
}

export interface IAlertDefinition$IAlertOutputSettings {
  message: string;
  subscribedUsers: number[];
}

export interface IAlertDefinition$IAlertParameter {
  name: string;
  type: string;
  value: string;
}

