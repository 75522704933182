import React, { lazy, useMemo } from 'react';
import {
  FaTools,
  FaInfoCircle,
  FaTruck,
  FaWarehouse,
  FaTruckLoading,
  FaHome,
  FaBarcode,
  FaPallet,
  FaDolly,
  FaCalculator,
  FaSync,
  FaBoxes,
  FaMapMarker,
  FaUserCog,
  FaUsers,
  FaChartLine,
  FaCogs,
  FaPlus,
  FaStream,
  FaShoppingCart,
  FaPlug,
  FaList,
  FaLanguage,
  FaClipboardList,
  FaAlignJustify,
  FaUniversalAccess,
  FaTasks,
  FaDev,
  FaFileImport,
  FaServicestack,
  FaAddressBook,
  FaShip,
  FaEuroSign,
  FaHistory,
} from 'react-icons/fa';
import {
  GoReport,
  GoUnverified
} from 'react-icons/go'
import {
  DiGoogleAnalytics
} from 'react-icons/di'
import {
  SiBaremetrics, SiFilezilla
} from 'react-icons/si'
import { GiCardDraw, GiCargoShip, GiHammerNails, GiKeyboard, GiLaserBurst, GiMaterialsScience, GiReturnArrow, GiSandsOfTime } from 'react-icons/gi';
import { GrDocumentUpdate, GrDocumentVerified, GrMultiple } from 'react-icons/gr';
import { VscMultipleWindows, VscTypeHierarchySub } from 'react-icons/vsc';
import { MdFormatListNumbered, MdOutlineShoppingCartCheckout, MdOutlineTipsAndUpdates, MdPhonelinkSetup } from 'react-icons/md';
import { AiFillApi, AiFillExperiment, AiFillPropertySafety, AiOutlineCaretRight, AiOutlineFunction } from 'react-icons/ai';
import { Role, IAuthenticatedUser, IConfigDto, IExtensions, SystemOperationMode } from 'interfaces';
import { notNull } from 'component_utils/utils';
import { cloneDeep } from 'lodash';
import namedLazy from 'components/namedLazy';
import { IconType } from 'react-icons';
import { ICurrentDeviceConfig, useAuth, useDeviceConfig } from 'utils/API';
import { useConfig } from 'utils/Config';
import { useSpecializations } from 'utils/SpecializationProvider';
import { BsBroadcastPin, BsBrowserChrome, BsPrinter } from 'react-icons/bs';
import { BiSolidReport } from 'react-icons/bi';
import { RiCalendarScheduleFill } from 'react-icons/ri';
import { IoAlert } from 'react-icons/io5';

const LabelGenerator = lazy(() => import('routes/tools/LabelGenerator'));
const UserFunctions = lazy(() => import('routes/tools/UserFunctions'));
const WorkConfirmationUserView = namedLazy(() => import('routes/production/WorkConfirmation'), 'WorkConfirmationUserView');
const WorkConfirmationAdminView = namedLazy(() => import('routes/production/WorkConfirmation'), 'WorkConfirmationAdminView');
const PurchaseOrderImporter = namedLazy(() => import('routes/importers/OrderImporter'), 'PurchaseOrderImporter');
const SalesOrderImporter = namedLazy(() => import('routes/importers/OrderImporter'), 'SalesOrderImporter');
const StandardReplenishment = namedLazy(() => import('routes/warehouse/replenishment'), 'StandardReplenishment');
const KardexReplenishment = namedLazy(() => import('routes/warehouse/replenishment'), 'KardexReplenishment');
const RecountRequestList = namedLazy(() => import('routes/warehouse/recounting'), 'RecountRequestList');
const RecountDocumentProcessor = namedLazy(() => import('routes/warehouse/recounting'), 'RecountDocumentProcessor');
const WtwRequestList = namedLazy(() => import('routes/warehouse/wall_to_wall/single'), 'WtwRequestList');
const WtwProcessRequest = namedLazy(() => import('routes/warehouse/wall_to_wall/single'), 'WtwProcessRequest');
const WallToWallBatch = lazy(() => import('routes/warehouse/wall_to_wall/batching'))
const WtwDoBatchCounting = namedLazy(() => import('routes/warehouse/wall_to_wall/batching'), 'WtwDoBatchCounting');
const DeliveryDispatch = lazy(() => import('routes/outbound/DeliveryDispatch'));
const batchCorrection = lazy(() => import('routes/warehouse/corrections/batchCorrection'));
const FileManager = lazy(() => import('routes/tools/FileManager'));
const Updater = lazy(() => import('routes/tools/Updater'));
const ItemPriceImporter = lazy(() => import('routes/importers/ItemPriceImporter'));
const ItemTranslationImporter = lazy(() => import('routes/importers/ItemTranslationImporter'));
const UserSettingsForCurrentUser = lazy(() => import('routes/tools/user_settings/UserSettingsForCurrentUser'))
const KeyLogger = lazy(() => import('routes/dev/KeyLogger'));
const Reporter = lazy(() => import('routes/tools/Reporter'));
const ExactOnlineBrowserConfig = lazy(() => import('routes/tools/ExactOnline/BrowserExtensionConfiguration'));
const StockTransfer = lazy(() => import('routes/warehouse/stock_transfer'));
const MultiStockTransfer = lazy(() => import('routes/warehouse/multi_stock_transfer'));
const FinishedGoodsReceipts = lazy(() => import('routes/inbound/FinishedGoodsReceipts'));
const GoodsReceiptList = lazy(() => import('routes/inbound/GoodsReceipt'));
const GoodsReceiptRequest = lazy(() => import('routes/inbound/GoodsReceipt/request'));
const ItemInfo = lazy(() => import('routes/warehouse/item_info'));
const LocationInfo = lazy(() => import('routes/warehouse/location_info'));
const SystemConfiguration = lazy(() => import('routes/tools/SystemConfigurations'));
const PackAndDeliveryList = namedLazy(() => import('routes/outbound/delivery'), 'PackAndDeliveryList');
const PackAndDeliveryProcessor = namedLazy(() => import('routes/outbound/delivery/process'), 'PackAndDeliveryProcessor');
const PackList = namedLazy(() => import('routes/outbound/delivery'), 'PackList');
const PackProcessor = namedLazy(() => import('routes/outbound/delivery/process'), 'PackProcessor');
const ExplicitDelivery = lazy(() => import('routes/outbound/delivery/explicitDelivery'));
const PackingSlip = lazy(() => import('routes/outbound/packing_slip'));
const StockStorage = lazy(() => import('routes/warehouse/stock_storage'));
const StockStorageRequest = lazy(() => import('routes/warehouse/stock_storage/request'));
const PickAndCollect = lazy(() => import('routes/outbound/PickAndCollect'));
const PickAndCollectProcess = lazy(() => import('routes/outbound/PickAndCollect/process'));
const StockPicking = lazy(() => import('routes/outbound/stock_picking/pick_list'));
const StockPickingList = lazy(() => import('routes/outbound/stock_picking'));
const PeripheralConfig = lazy(() => import('routes/tools/peripheral_config'));
const PeripheralTest = lazy(() => import('routes/tools/peripheral_config/test'));
const Dashboard = lazy(() => import('routes/misc/dashboard'));
const PicklistGenerator = lazy(() => import('routes/outbound/pick_and_pack'));
const PicklistOverview = lazy(() => import('routes/outbound/PicklistOverview'));
const TranslationManager = lazy(() => import('routes/tools/translationManager'));
const ItemBinLocationConfigurer = lazy(() => import('routes/setup/ItemBinLocationConfigurer'));
const LocationStockTransfer = lazy(() => import('routes/warehouse/LocationStockTransfer'));
const GoodsSorting = lazy(() => import('routes/inbound/GoodsSorting'));
const GoodsSortingLocation = lazy(() => import('routes/inbound/GoodsSorting/Location'));
const RestApiQueryEditor = lazy(() => import('routes/RestApiConfigurator'));
const RestApiClientAccess = lazy(() => import('routes/RestApiClientAccess'));
const KardexStorage = lazy(() => import('routes/warehouse/kardexStorage'));
const TaskManager = lazy(() => import('routes/tools/TaskManager'));
const ProductFinishedConfirmation = lazy(() => import('routes/production/ProductFinishedConfirmation'));
const MaterialConfirmation = lazy(() => import( 'routes/production/MaterialConfirmation'));
const BurstTest = lazy(() => import('routes/dev/BurstTest'));
const Playground = lazy(() => import('routes/dev/playground'));
const CountingListsCreator =  lazy(() => import('routes/setup/CountingListsCreator'));
const AnalyticsHelper = lazy(() => import('routes/tools/AnalyticsHelper'));
const BatchAddressDelivery = lazy(() => import('routes/outbound/BatchAddressDelivery'));
const SalesGoodsReturns = lazy(() => import('routes/inbound/GoodsReturns'));
const SalesGoodsReturnsProcessor = lazy(() => import('routes/inbound/GoodsReturns/process'));
const AddressValidation = lazy(() => import('routes/outbound/AddressValidation'));
const WhsPerformance = lazy(() => import('routes/tools/whs_performance'));
const CreateWidget = lazy(() => import('routes/tools/whs_performance/widgets/create'));
const EmployeeManagement = lazy(() => import('routes/tools/EmployeeManagement'));
const ItemBarcoder = lazy(() => import('routes/setup/ItemBarcoder'));
const StateMachineTests = lazy(() => import('routes/dev/StateMachineTests'));
const RoleHierarchy = lazy(() => import('routes/dev/RoleHierarchy'));
const ServiceDiscovery = lazy(() => import('routes/tools/ServiceOverview'));
const PerformQualityControl = lazy(() => import('routes/qualityControl/PerformQualityControl'))
const VerifyQualityControl = lazy(() => import('routes/qualityControl/VerifyQualityControl'))
const PrinterTool = lazy(() => import('routes/tools/PrinterTool'))
const BroadCaster = lazy(() => import('routes/tools/BroadCaster'))
const ClaimOverview = lazy(() => import('routes/tools/ClaimOverview'))
const LogViewer = lazy(() => import('routes/tools/LogViewer'))
const About = lazy(() => import('routes/misc/about'));
const PlannedGoodsReceiptsDraftImporter = lazy(() => import('routes/importers/PlannedGoodsReceiptsDraftImporter'));
const ProductionPlanner = lazy(() => import('routes/production/ProductionPlanner'));
const Alerting = lazy(() => import('routes/tools/Alerting'));

export interface IApp extends ReturnType<typeof app> {}
export interface IAppGroup extends ReturnType<typeof group> {}
export type Condition = {
  appRole?: Role;
  extensions?: Array<keyof IExtensions>;
  operationModes?: Array<SystemOperationMode>;

  appAuthorizations?: Role[];

  isDevApp?: boolean;

  // extra verifications
  extraChecks?: (user: IAuthenticatedUser, config: IConfigDto, deviceConfig: ICurrentDeviceConfig) => boolean
};
export type IChild = IApp | IAppGroup;
export type IRouteDefinition = IChild

export function isApp(x: IChild): x is IApp {
  if (x.type === 'app') {
    return true;
  }
  return false;
}

export function condition(
  appRole: Role,
  extensions: Array<keyof IExtensions>,
  operationModes: Array<SystemOperationMode>,
  appAuthorizations: Role[],
  extraChecks?: (user: IAuthenticatedUser, config: IConfigDto, deviceConfig: ICurrentDeviceConfig) => boolean
): Condition {
  return {
    appRole, 
    extensions, 
    operationModes,
    appAuthorizations,
    extraChecks
  }
}

/**
 * methods to create children
 */
const idMenuMapping: {[k:string]:IChild} = {}
export function app(
  id: string,
  path: string,
  title: string,
  icon: IconType,
  component: React.ComponentType,
  subComponents: { [k: string]: React.ComponentType },
  condition: Condition,
  options?: {
    allowAsSubApp: boolean
  }
) {
  const app = {
    id,
    type: 'app',
    path,
    title,
    icon,
    component,
    subComponents,
    condition,
    options,
  };
  if (idMenuMapping[id]) {
    console.error("duplicate menu id detected", id)
  }
  idMenuMapping[id] = app
  return app
}

export function group(id: string, title: string, icon: IconType, children: IChild[]) {
  const group = {
    id,
    type: 'group',
    title,
    icon,
    children,
  };
  if (idMenuMapping[id]) {
    console.error("duplicate menu id detected", id)
  }
  idMenuMapping[id] = group
  return group
}

/**
 * These are the actual possible paths
 * Current head is: '78'
 */
const data: IChild[] = [
  app('0', '/dashboard', 'T.menu.dashboard', FaHome, Dashboard, {}, condition(null, [], [], [])),

  group('1', 'T.menu.warehouse.group', FaWarehouse, [
    app(
      '2',
      '/item/item_info',
      'T.menu.warehouse.itemInfo',
      FaBarcode,
      ItemInfo,
      { '/:itemcode': ItemInfo },
      condition('information', ['informationApps'], ['NORMAL'], ['itemManagedByEdit']),
      {
        allowAsSubApp: true
      }
    ),
    app(
      '3',
      '/location/location_info',
      'T.menu.warehouse.locationInfo',
      FaMapMarker,
      LocationInfo,
      { '/:bincode': LocationInfo },
      condition('information', ['informationApps'], ['NORMAL'], []),
      {
        allowAsSubApp: true
      }
    ),
    app(
      '4',
      '/item/stock_transfer',
      'T.menu.warehouse.stockTransfer',
      FaDolly,
      StockTransfer,
      {},
      condition('stockTransfer', ['basic'], ['NORMAL'], []),
      {
        allowAsSubApp: true
      }
    ),
    app(
      '76',
      '/item/multi_stock_transfer',
      'T.menu.warehouse.multiStockTransfer',
      FaDolly,
      MultiStockTransfer,
      {},
      condition('stockTransfer', ['basic'], ['NORMAL'], []),
      {
        allowAsSubApp: true
      }
    ),
    app(
      '5',
      '/item/location_stock_transfer',
      'T.menu.warehouse.locationStockTransfer',
      FaDolly,
      LocationStockTransfer,
      {},
      condition('stockTransfer', ['basic'], ['NORMAL'], []),
      {
        allowAsSubApp: true
      }
    ),
    app(
      '6',
      '/wall_to_wall',
      'T.menu.warehouse.stockCounting',
      FaCalculator,
      WtwRequestList,
      { '/request': WtwProcessRequest },
      condition('counting', ['wallToWall'], ['NORMAL', 'WALL_TO_WALL_BATCHING_MODE'], ['countingAuthManageClaims', 'countingAuthAllowDirectInventoryPosting', 'countingAuthAllowChoosingWhetherToIncludeAllItems']),
    ),
    app(
      '7',
      '/wall_to_wall_batch',
      'T.menu.warehouse.stockCountingBatch',
      FaCalculator,
      WallToWallBatch,
      { '/process': WtwDoBatchCounting },
      condition('counting', ['wallToWall'], ['WALL_TO_WALL_BATCHING_MODE'], ['countingAuthManageClaims', 'countingAuthAllowDirectInventoryPosting']),
    ),
    app(
      '8',
      '/recounting',
      'T.menu.warehouse.recounting',
      FaCalculator,
      RecountRequestList,
      { '/request': RecountDocumentProcessor },
      condition('recounting', ['wallToWall'], ['WALL_TO_WALL_BATCHING_MODE'], ['recountingAuthManageClaims']),
    ),
    group('9', 'T.menu.warehouse.corrections.groupname', GrDocumentUpdate, [
      app(
        '10',
        '/corrections/batch_correction',
        'T.menu.warehouse.corrections.batchCorrection',
        GrDocumentUpdate,
        batchCorrection,
        {},
        condition('stockCorrections', ['wallToWall'], ['NORMAL'], []),
        {
          allowAsSubApp: true
        }
      ),
    ]),
    app(
      '11',
      '/replenishment',
      'T.menu.warehouse.replenishment',
      FaSync,
      StandardReplenishment,
      {},
      condition('replenishment', ['stockTransferEnhancements'], ['NORMAL'], []),
    ),
    app(
      '12',
      '/stock_storage',
      'T.menu.warehouse.stockStorage',
      FaBoxes,
      StockStorage,
      { '/request': StockStorageRequest },
      condition('stockStorage', ['stockTransferEnhancements'], ['NORMAL'], []),
    ),
    group('13', 'T.misc.kardex', FaAlignJustify, [
      app(
        '14',
        '/kardex/replenishment',
        'T.kardex.replenishment',
        FaSync,
        KardexReplenishment,
        {},
        condition('kardexReplenishment', ['kardexIntegration'], ['NORMAL'], []),
      ),
      app(
        '15',
        '/kardex/storage',
        'T.kardex.storage.title',
        FaBoxes,
        KardexStorage,
        {},
        condition('kardexStorage', ['kardexIntegration'], ['NORMAL'], []),
      ),
    ]),
  ]),

  group('16', 'T.menu.inbound.group', FaTruck, [
    app(
      '17',
      '/goods_receipt',
      'T.menu.inbound.goodsReceipt',
      FaPallet,
      GoodsReceiptList,
      { '/:id': GoodsReceiptRequest },
      condition('goodsReceipt', ['basic'], ['NORMAL'], ['goodsReceiptAuthManageClaims']),
    ),
    app(
      '18',
      '/goods_sorting',
      'T.menu.inbound.goodsSorting',
      FaPallet,
      GoodsSorting,
      { '/location': GoodsSortingLocation },
      condition('goodsSorting', ['basic'], ['NORMAL'], []),
    ),
    app(
      '19',
      '/goods_returns',
      'T.menu.inbound.salesGoodsReturns',
      GiReturnArrow,
      SalesGoodsReturns,
      {
        '/:id': SalesGoodsReturnsProcessor
      },
      condition('salesReturns', ['basic'], ['NORMAL'], ['salesReturnsAuthManageClaims']),
    ),
    app(
      '71',
      '/finished_goods_receipt',
      'T.menu.inbound.finishedGoodsReceipt',
      FaHistory,
      FinishedGoodsReceipts,
      {},
      condition('goodsReceipt', ['basic'], ['NORMAL'], []), 
      { allowAsSubApp: true }
    ),
  ]),

  group('20', 'T.menu.production.group', GiHammerNails, [
    app(
      '77',
      '/production/planner',
      'T.menu.production.planner',
      RiCalendarScheduleFill,
      ProductionPlanner,
      {},
      condition('productionPlanner', ['production'], ['NORMAL'], ['productionPlannerAuthEditPlanning']),
    ),
    app(
      '21',
      '/production/material_confirmation',
      'T.menu.production.materialConfirmation',
      GrMultiple,
      MaterialConfirmation,
      { '/:step': MaterialConfirmation },
      condition('production', ['production'], ['NORMAL'], []),
    ),
    app(
      '22',
      '/production/product_confirmation',
      'T.menu.production.productConfirmation',
      GiMaterialsScience,
      ProductFinishedConfirmation,
      {},
      condition('production', ['production'], ['NORMAL'], []),
    ),
    app(
      '23',
      '/production/work_confirmation',
      'T.menu.production.workConfirmation',
      GiSandsOfTime,
      WorkConfirmationUserView,
      {},
      condition('production', ['production'], ['NORMAL'], []),
    ),
    app(
      '24',
      '/production/work_confirmation_verification',
      'T.menu.production.workConfirmationVerification',
      GrDocumentVerified,
      WorkConfirmationAdminView,
      {},
      condition('productionManager', ['production'], ['NORMAL'], []),
    ),
  ]),

  group('25', 'T.menu.outbound.group', FaTruckLoading, [
    app(
      '26',
      '/pick_and_pack',
      'T.menu.outbound.pickAndPack',
      FaPlus,
      PicklistGenerator,
      {},
      condition('picklistGenerator', ['pickAndPack'], ['NORMAL'], ['picklistGeneratorAuthCreatePicklist', 'picklistGeneratorAuthConfigureAutoGenerator']),
    ),
    app(
      '27',
      '/stock_picking',
      'T.menu.outbound.stockPicking',
      FaShoppingCart,
      StockPickingList,
      { '/process': StockPicking },
      condition('picking', ['basic'], ['NORMAL'], ['pickingAuthManageClaims']),
    ),
    app(
      '74',
      '/pick_and_collect',
      'T.menu.outbound.pickAndCollect',
      MdOutlineShoppingCartCheckout,
      PickAndCollect,
      { '/process': PickAndCollectProcess },
      condition('pickAndCollect', ['basic'], ['NORMAL'], []),
    ),
    app(
      '28',
      '/pack_and_delivery',
      'T.menu.outbound.packAndDelivery',
      FaTruckLoading,
      PackAndDeliveryList,
      {
        '/process': PackAndDeliveryProcessor,
      },
      condition('delivery', ['basic'], ['NORMAL'], ['deliveryAuthManageClaims', 'deliveryAuthApproveIncompletePicklists'], (_, config) => {
        return !config.settings.delivery.splitPackAndDelivery
      }),
    ),
    app(
      '28_1',
      '/pack',
      'T.menu.outbound.pack',
      FaTruckLoading,
      PackList,
      {
        '/process': PackProcessor,
      },
      condition('delivery', ['basic'], ['NORMAL'], ['deliveryAuthManageClaims', 'deliveryAuthApproveIncompletePicklists'], (_, config) => {
        return config.settings.delivery.splitPackAndDelivery
      }),
    ),
    app(
      '28_2',
      '/delivery',
      'T.menu.outbound.delivery',
      FaTruckLoading,
      ExplicitDelivery,
      {},
      condition('delivery', ['basic'], ['NORMAL'], ['deliveryAuthManageClaims', 'deliveryAuthApproveIncompletePicklists'], (_, config) => {
        return config.settings.delivery.splitPackAndDelivery
      }),
    ),
    app(
      '29',
      '/delivery_dispatch',
      'T.menu.outbound.deliveryDispatch',
      FaShip,
      DeliveryDispatch,
      {},
      condition('delivery', ['basic'], ['NORMAL'], [], (_, config) => {
        return config.settings.delivery.enableDeliveryDispatchApplication
      })
    ),
    app(
      '30',
      '/packing_slip',
      'T.menu.outbound.packingSlip',
      FaStream,
      PackingSlip,
      {},
      condition('delivery', ['packingSlip'], ['NORMAL'], []), 
      { allowAsSubApp: true }
    ),
    app(
      '31',
      '/multi_address_delivery',
      'T.menu.outbound.multiAddressDelivery',
      VscMultipleWindows,
      BatchAddressDelivery,
      {},
      condition('multiAddressDeliveries', ['batchDelivery'], ['NORMAL'], []),
    ),
    app(
      '32',
      '/address_validation',
      'T.menu.outbound.addressValidation',
      FaAddressBook,
      AddressValidation,
      {},
      condition('addressValidation', ['basic'], ['NORMAL'], []),
    ),
    app(
      '33',
      '/picklists',
      'T.menu.outbound.picklistOverview',
      FaList,
      PicklistOverview,
      {},
      condition('picklistOverview', ['basic'], ['NORMAL'], ['picklistOverviewAuthEditPicklists']),
    ),
  ]),

  group('34', 'T.menu.importers.group', FaFileImport, [
    app(
      '35',
      '/order/purchase_importer',
      'T.menu.importers.purchaseOrderImporter',
      FaFileImport,
      PurchaseOrderImporter,
      {},
      condition('purchasing', ['orderImporters'], [], []),
    ),
    app(
      '36',
      '/order/importer',
      'T.menu.importers.salesOrderImporter',
      FaFileImport,
      SalesOrderImporter,
      {},
      condition('sales', ['orderImporters'], [], []),
    ),
    app(
      '69',
      '/order/item_price_importer',
      'T.menu.importers.itemPriceImporter',
      FaEuroSign,
      ItemPriceImporter,
      {},
      condition('priceListUpload', [], [], []),
    ),
    app(
      '70',
      '/order/item_translation_importer',
      'T.menu.importers.itemTranslationImporter',
      FaLanguage,
      ItemTranslationImporter,
      {},
      condition('itemTranslationUpload', [], [], []),
    ),
    app(
      '72',
      '/shipment/planned_goods_receipts_importer',
      'T.menu.importers.plannedGoodsReceiptsImporter',
      GiCargoShip,
      PlannedGoodsReceiptsDraftImporter,
      {},
      condition('plannedGoodsReceiptsDraftImporter', [], [], []),
    )
  ]),

  group('37', 'T.menu.qualityControl.group', GoUnverified, [
    app(
      '38',
      '/qc/perform_qc',
      'T.menu.qualityControl.perform_qc',
      GoReport,
      PerformQualityControl,
      { '/:step': PerformQualityControl },
      condition('qualityControl', [], [], [])
    ),
    app(
      '39',
      '/qc/verify_qc',
      'T.menu.qualityControl.verify_qc',
      GoReport,
      VerifyQualityControl,
      { '/:step': VerifyQualityControl },
      condition('qualityControl', [], [], [], (_, config) => {
        return config.settings.qualityControlApproach === 'PERFORM_VERIFY_SEPARATE_STEP'
      })
    )
  ]),

  group('40', 'T.menu.setup.group', MdPhonelinkSetup, [
    app(
      '41',
      '/setup/item_barcoder',
      'T.menu.setup.item_barcoder',
      FaBarcode,
      ItemBarcoder,
      {},
      condition('barcodeSetup', [], [], []), 
      { allowAsSubApp: true }
    ),
    app(
      '42',
      '/default_item_location_configurer',
      'T.menu.warehouse.defaultItemLocationConfigurer',
      FaClipboardList,
      ItemBinLocationConfigurer,
      {},
      condition('defaultItemBinLocationEdit', ['itemDefaultLocationUpdater'], ['NORMAL'], []),
    ),
    app(
      '43',
      '/counting_list_creator',
      'T.menu.warehouse.countingListCreator',
      MdFormatListNumbered,
      CountingListsCreator,
      {},
      condition('countingListCreator', ['wallToWall'], ['NORMAL'], []),
    ),
  ]),

  group('44', 'T.menu.tools.group', FaTools, [
    app(
      '45',
      '/admin/system_config',
      'T.menu.tools.systemConfig',
      FaCogs,
      SystemConfiguration,
      {},
      condition('admin', [], [], []),
    ),
    app(
      '68',
      '/admin/system_updater',
      'T.menu.tools.systemUpdater',
      MdOutlineTipsAndUpdates,
      Updater,
      {},
      condition('admin', [], [], []),
    ),
    app(
      '46',
      '/admin/employee_info', 
      'T.menu.tools.employeeInfo', 
      FaUsers, 
      EmployeeManagement, 
      {}, 
      condition('userManager', [], [], ['userManagerAuthScreenCapture'])
    ),
    app(
      '47',
      '/admin/whs_performance',
      'T.menu.tools.whsPerformance',
      FaChartLine,
      WhsPerformance,
      { '/create': CreateWidget },
      condition('whsPerformance', ['whsPerformance'], [], ['whsPerformanceAuthCreateWidgets']),
    ),
    app(
      '48',
      '/admin/language_manager',
      'T.menu.tools.languageManagement',
      FaLanguage,
      TranslationManager,
      {},
      condition('translationManager', [], [], []),
    ),
    app('49', '/user/settings', 'T.menu.tools.userSettings', FaUserCog, UserSettingsForCurrentUser, {}, condition(null, [], [], [])),
    app('50', '/taskmanager', 'T.menu.tools.taskManager', FaTasks, TaskManager, {}, condition('admin', [], [], [])),
    app('51', '/serviceDiscovery', 'T.menu.tools.serviceDiscovery', FaServicestack, ServiceDiscovery, {}, condition('admin', [], [], [])),
    app('52', '/analyticsTools', 'T.menu.tools.analyticsTools', DiGoogleAnalytics, AnalyticsHelper, {}, condition('admin', [], [], [])),
    app('53', '/broadCaster', 'T.menu.tools.broadCaster', BsBroadcastPin, BroadCaster, {}, condition('broadcasting', [], [], [])),
    app('54', '/claimOverview', 'T.menu.tools.claimOverview', AiFillPropertySafety, ClaimOverview, {}, condition(null, [], [], [])),
    app('55', '/printerTool', 'T.menu.tools.printerTool', BsPrinter, PrinterTool, {}, condition('batchPrinting', [], [], [])),
    app('56', '/reporter', 'T.menu.tools.reporter', BiSolidReport, Reporter, {}, condition('reporting', [], [], ['reportingAuthCreateReports'])),
    app('73', '/user_functions', 'T.menu.tools.user_functions', AiOutlineFunction, UserFunctions, {}, condition('userFunctions', [], [], ['userFunctionsAuthCreateUserFunctions'])),
    app('78', '/alerting', 'T.menu.tools.alerting', IoAlert, Alerting, {}, condition('alerting', [], [], ['alertingAuthCreateAlerts'])),
    app('75', '/label_designer', 'T.menu.tools.label_designer', GiCardDraw, LabelGenerator, {}, condition('labelGenerator', [], [], ['labelGeneratorAuthDesignLabels'])),
    app('57', '/logviewer', 'T.menu.tools.logviewer', SiBaremetrics, LogViewer, {}, condition('admin', [], [], [])),
    app('58', '/fileManager', 'T.menu.tools.fileManager', SiFilezilla, FileManager, {}, condition('admin', [], [], [])),
    group('59', "T.menu.tools.exactOnline.title", AiOutlineCaretRight, [
      app(
        '60',
        '/exact_online/config',
        'T.menu.tools.exactOnline.config',
        BsBrowserChrome,
        ExactOnlineBrowserConfig,
        {},
        condition('admin', [], [], [], (_, config) => {
          return config.erpType === 'EXACT_ONLINE'
        })
      )
    ]),
    group('61', 'T.menu.tools.restApi', AiFillApi, [
      app(
        '62',
        '/admin/restApi',
        'T.menu.tools.restApiQueryConfigurator',
        FaCogs,
        RestApiQueryEditor,
        {},
        condition('restApiConfigurator', [], [], []),
      ),
      app(
        '63',
        '/admin/restClientAccess',
        'T.menu.tools.restApiClientAccess',
        FaUniversalAccess,
        RestApiClientAccess,
        {},
        condition('restApiAccessManager', [], [], []),
      ),
    ]),
  ]),

  group('d1', 'DEV', FaDev, [
    app('d2', '/dev/stateMachine', 'T.menu.tools.dev.statemachineTest', FaChartLine, StateMachineTests, {}, { isDevApp: true }),
    app('d3', '/dev/rolehierarchy', 'T.menu.tools.dev.roleHierarchy', VscTypeHierarchySub, RoleHierarchy, {}, { isDevApp: true }),
    app('d4', '/dev/burst', 'T.menu.tools.dev.burst', GiLaserBurst, BurstTest, {}, { isDevApp: true }),
    app('d5', '/dev/keylogger', 'T.menu.tools.dev.keylogger', GiKeyboard, KeyLogger, {}, { isDevApp: true }),
    app('d6', '/dev/playground', 'T.menu.tools.dev.playground', AiFillExperiment, Playground, {}, { isDevApp: true }),
    group('64', 'T.menu.tools.peripheral.group', FaPlug, [
      app(
        '65',
        '/admin/peripheral_config',
        'T.menu.tools.peripheral.peripheralConfig',
        FaCogs,
        PeripheralConfig,
        {},
        { isDevApp: true },
      ),
      app(
        '66',
        '/admin/peripheral_test',
        'T.menu.tools.peripheral.peripheralTest',
        FaCogs,
        PeripheralTest,
        {},
        { isDevApp: true },
      ),
    ]),
  ]),

  app('67', '/about', 'T.menu.about', FaInfoCircle, About, {}, condition(null, [], [], [])),
];

/*
 * The next methods are used to retrieve the menu's for specific users
 */
function checkHasAccess(
  condition: Condition,
  user: IAuthenticatedUser, 
  config: IConfigDto,
  deviceConfig: ICurrentDeviceConfig
) {
  if (condition.isDevApp) {
    const isDevEnv = (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'test' ||
      process.env.CYPRESS ||
      config.runtimeType === 'TEST' ||
      (window as any).Cypress
    );
    if (!isDevEnv) {
      return false
    }
  }

  let shouldShow = true;
  if (condition.operationModes?.length) {
    shouldShow = shouldShow && [...condition.operationModes, 'ALL'].includes(config.settings.systemOperationMode);
  }

  if (condition.appRole) {
    shouldShow = shouldShow && user.expandedRoles.includes(condition.appRole);
  }

  // atleast one of the extensions should be enabled
  if (condition.extensions?.length) {
    shouldShow = shouldShow && condition.extensions.some((en) => config.extensions[en]);
  }

  // maybe there are still requirements based on settings
  if (condition.extraChecks) {
    shouldShow = shouldShow && condition.extraChecks(user, config, deviceConfig);
  }

  return shouldShow;
}

function _getMenu(
  children: IChild[],
  user: IAuthenticatedUser,
  config: IConfigDto,
  deviceConfig: ICurrentDeviceConfig,
) {
  type mapper = (x: IChild) => IChild | null;
  const apply = (l: IChild[], f: mapper): IChild[] => l.map(f).filter(notNull);

  const menuFilter = (item: IChild) => {
    if (isApp(item)) {
      if (checkHasAccess(item.condition, user, config, deviceConfig)) return item;
      else return null;
    } else {
      const filteredChildren = apply(item.children, menuFilter);
      if (filteredChildren.length) return {
        ...item,
        children: filteredChildren
      };
      else return null;
    }
  };
  return apply(children, menuFilter);
}

function _getFlatMenu(
  children: IChild[],
  user: IAuthenticatedUser,
  config: IConfigDto,
  deviceConfig: any,
  allApps: Array<IApp> = [],
) {
  children.forEach((item) => {
    if (isApp(item)) {
      if (checkHasAccess(item.condition, user, config, deviceConfig)) allApps.push(item);
    } else {
      _getFlatMenu(item.children, user, config, deviceConfig, allApps);
    }
  });
  return allApps;
}

function getRouteNames(apps: Array<IApp>) {
  const allNames: { [x: string]: { title: string } } = {};
  apps.forEach((item) => {
    allNames[item.path] = { title: item.title };
  });
  return allNames;
}

function useAugmentedData() {
  const specialization = useSpecializations()
  return useMemo(() => {
    const baseData = cloneDeep(data)
    if (specialization.routesCustomizer) {
      return specialization.routesCustomizer(baseData)
    }
    return baseData
  }, [specialization])
}

// make the exports
export const generalAuthenticationToggles: Role[] = ['admin', 'authCopyObjectCodes', 'authEditProtectedDeviceSettings']

export const useRawMenuStructure = () => {
  return useAugmentedData()
}

export const useMenu = () => {
  const user = useAuth();
  const config = useConfig();
  const deviceConfig = useDeviceConfig().deviceConfig;
  const augmentedData = useAugmentedData()

  return useMemo(() => {
    return _getMenu(augmentedData, user, config, deviceConfig);
  }, [augmentedData, user, config, deviceConfig]);
};

export const useFlatMenu = () => {
  const user = useAuth();
  const config = useConfig();
  const deviceConfig = useDeviceConfig().deviceConfig;
  const augmentedData = useAugmentedData()

  return useMemo(() => {
    return _getFlatMenu(augmentedData, user, config, deviceConfig);
  }, [augmentedData, user, config, deviceConfig]);
};

export const getRouteNamesFromFlatMenu = (apps: Array<IApp>) => getRouteNames(cloneDeep(apps));
export type RouteNames = ReturnType<typeof getRouteNames>;
