import SimpleModal, { ModalRef } from 'components/SimpleModal';
import { entries, noop } from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import Translation from './Language/Translation';
import PeripheralPrinterListener from 'components/PeripheralPrinterListener';
import { Button, ButtonGroup, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { run, sleep, useLocalStorage, withTimeout } from 'component_utils/utils';
import ProcessingButton from 'components/ProcessingButton';
import * as Immutable from 'object-path-immutable'
import { FaBalanceScale, FaCogs, FaCopy, FaExclamationTriangle, FaPrint, FaTrash } from 'react-icons/fa';
import ZebraBle from 'component_utils/peripherals/peripheralPrinters/ZebraBle';
import { addNotification } from './NotificationManager';
import QZTray from 'component_utils/peripherals/peripheralPrinters/QZTray';
import BrowserPrint from 'component_utils/peripherals/peripheralPrinters/BrowserPrint';
import StimagScale from 'component_utils/peripherals/scales/STIMAG';
import { beTableAsComponent } from 'components/BeTable';
import DownloadPrint from 'component_utils/peripherals/peripheralPrinters/DowloadPrinter';
import TestScale from 'component_utils/peripherals/scales/TestScale';
import TestCartoncube from 'component_utils/peripherals/cartoncube/TestCartoncube';
import ParcelCube from 'component_utils/peripherals/cartoncube/ParcelCube';

interface PeripheralInterface<T = any> {
  setConfig?: (config: any) => any;
  getDriverName: () => string;
  isConnected: () => Promise<boolean>;
  disconnect: () => Promise<void>;
  connect: (data: any) => Promise<any>;
  getMaintenanceComponent: () => FC<{ device: T }>
}

export interface PeripheralPrinter<T = any> extends PeripheralInterface<T> {
  print: (mediatype: string, payload: string) => Promise<void>;
}

export interface InputPeripheral<T = any> extends PeripheralInterface<T> {
  getValue: () => Promise<string>
}

type PeripheralUsages = 'printer' | 'scale' | 'put to light' | 'cartoncube'
export const PeripheralRegistry: {[k in PeripheralUsages]: { [k:string]: { new(): PeripheralInterface<any> } }} = {
  printer: {
    ZEBRA_BLE: ZebraBle,
    QZTray: QZTray,
    BrowserPrint: BrowserPrint,
    DownloadPrint: DownloadPrint,
  },
  scale: {
    STIMAG: StimagScale,
    TEST: TestScale,
  },
  "put to light": {

  },
  cartoncube: {
    TEST: TestCartoncube,
    PARCELCUBE: ParcelCube,
  },
}

interface RegisteredPeripheral {
  usage: PeripheralUsages;
  driverType: string;
  name: string;
  
  suppliedConfig: any;

  // used to automatically establish a connection
  automaticReconnectionData: any;
}

const Context = React.createContext<{}>(null);

export let openPeripheralManager = noop
export let connectToPeripheral = noop
export let getOneTimeInputFromPeripheral = (usage: PeripheralUsages, driverName: string, deviceName: string, config: any): Promise<string> => {
  throw addNotification('danger', 'N/A')
}

const rowLayouts = [
  [
    ['smallHeaderRow'],
  ],
]
const PeripheralTable = beTableAsComponent<[string, RegisteredPeripheral], any>({
  tableId: 'peripheralTable',
  rowLayout: {
    'xs': rowLayouts,
  },
  columns: [{
    Header: 'Peripheral status',
    id: 'smallHeaderRow',
    hideAbove: 'xs',
    Cell: ({ row: [id, registeredPeripheral], renderManagement }) => {
      const logo = run(() => {
        if (registeredPeripheral.usage === 'printer') return <FaPrint/>
        if (registeredPeripheral.usage === 'scale') return <FaBalanceScale/>
        return null  
      })

      return (
        <>
          {logo}{' - '}
          {registeredPeripheral.driverType}{' - '}
          {registeredPeripheral.name}
          <br/>
          {JSON.stringify(registeredPeripheral.automaticReconnectionData)}
          <br/>
          {renderManagement(id, registeredPeripheral)}
        </>
      )
    }
  }, {
    Header: '',
    id: 'type',
    Cell: ({ row: [, registeredPeripheral] }) => {
      if (registeredPeripheral.usage === 'printer') return <FaPrint/>
      if (registeredPeripheral.usage === 'scale') return <FaBalanceScale/>
      return null
    },
    width: 35,
    fixedWidth: true,
    resizable: false
  },{
    Header: '',
    id: 'driver',
    width: 100,
    value: ([, registeredPeripheral]) => registeredPeripheral.driverType
  },{
    Header: '',
    id: 'name',
    width: 100,
    value: ([, registeredPeripheral]) => registeredPeripheral.name
  },{
    Header: '',
    id: 'specs',
    width: 200,
    fullText: true,
    value: ([, registeredPeripheral]) => JSON.stringify(registeredPeripheral.automaticReconnectionData)
  },{
    Header: '',
    id: 'management',
    width: 250,
    Cell: ({ row: [id, registeredPeripheral], renderManagement }) => {
      return renderManagement(id, registeredPeripheral)
    }
  },]
})

export const PeripheralManager: FC = ({ children }) => {
  const modalRef = useRef<ModalRef>(null)
  const [registeredPeripherals, setRegisteredPeripherals] = useLocalStorage<{[k: string]: RegisteredPeripheral}>('PERIPHERALS', {})
  const [connectedPeriperhals, setConnectedPeripherals] = useState<{[k: string]: PeripheralInterface}>({})

  const connectAndAddDevice = async (id: string, usage: PeripheralUsages, driverName: string, deviceName: string, config: any): Promise<PeripheralInterface> => {
    let peripheralDriver: PeripheralInterface
    if (connectedPeriperhals[id]) {
      peripheralDriver = connectedPeriperhals[id]
    } else {
      const registeredPeripheral = registeredPeripherals[id]
      if (registeredPeripheral) {
        const Driver = PeripheralRegistry[usage][registeredPeripheral.driverType]
        peripheralDriver = new Driver() as PeripheralPrinter
        peripheralDriver.setConfig?.(registeredPeripheral.suppliedConfig)
        await peripheralDriver.connect(registeredPeripheral.automaticReconnectionData)
      } else {
        const Driver = PeripheralRegistry[usage][driverName]
        if (!Driver) {
          addNotification('danger', <Translation name="T.errors.peripheralTypeUnknown"/>)
          return
        }

        // create the peripheral and store the connection registration
        peripheralDriver = new Driver()
        peripheralDriver.setConfig?.(config)
        const newAutoConnectData = await peripheralDriver.connect(null)
        setRegisteredPeripherals(old => Immutable.set(old, id, {
          usage: usage,
          driverType: driverName,
          name: deviceName,
          suppliedConfig: config,
          automaticReconnectionData: newAutoConnectData
        } as RegisteredPeripheral))
      }
      setConnectedPeripherals(old => Immutable.set(old, id, peripheralDriver))
    }
    return peripheralDriver
  }

  useEffect(() => {
    openPeripheralManager = modalRef?.current?.toggleOpen
    return () => {
      openPeripheralManager = noop
    }
  }, [])

  useEffect(() => {
    getOneTimeInputFromPeripheral = async (usage: PeripheralUsages, driverName: string, deviceName: string, config: any) => {
      let peripheral: InputPeripheral
      let id = `${usage} - ${driverName} - ${deviceName}`
      const registeredPeripheral = registeredPeripherals[id]
      if (registeredPeripheral) {
        const Driver = PeripheralRegistry[usage][registeredPeripheral.driverType]
        peripheral = new Driver() as InputPeripheral
        peripheral.setConfig?.(registeredPeripheral.suppliedConfig)
        await peripheral.connect(registeredPeripheral.automaticReconnectionData)
      } else {
        const Driver = PeripheralRegistry[usage][driverName]
        if (!Driver) {
          addNotification('danger', <Translation name="T.errors.peripheralTypeUnknown"/>)
          return
        }

        // create the peripheral and store the connection registration
        peripheral = new Driver() as InputPeripheral
        peripheral.setConfig?.(config)
        const newAutoConnectData = await peripheral.connect(null)

        if (!(Driver as any)._do_not_register_device) {
          setRegisteredPeripherals(old => Immutable.set(old, id, {
            usage: usage,
            driverType: driverName,
            name: deviceName,
            suppliedConfig: config,
            automaticReconnectionData: newAutoConnectData
          } as RegisteredPeripheral))  
        }
      }

      try {
        await sleep(250)
        const v = await withTimeout(
          peripheral.getValue(),
          5000
        )
        return v as string
      } catch (e) {
        addNotification('danger', <Translation name="T.errors.couldNotReadInputFromPeripheralDevice"/>)
        throw e
      } finally {
        peripheral.disconnect()
      }
    }
    return () => {
      getOneTimeInputFromPeripheral = () => {
        throw addNotification('danger', 'N/A')
      }
    }
  }, [registeredPeripherals, setRegisteredPeripherals])

  return (
    <Context.Provider value={{}}>
      <PeripheralPrinterListener print={async (printerId, printerName, driverType, mediatype, payload) => {
        const peripheralId = `printer ${printerId}`
        let peripheralDriver = await connectAndAddDevice(peripheralId, 'printer', driverType, printerName, {}) as PeripheralPrinter
        peripheralDriver.print(mediatype, payload)
      }}/>
      <SimpleModal 
        title={
          <>
            <Translation name="T.misc.peripheralManager"/>
            {(navigator.bluetooth && !navigator.bluetooth.getDevices) && (
              <>
                {' '}
                <FaExclamationTriangle id='btFlagExplanation'/>
                <UncontrolledPopover target='btFlagExplanation' placement='bottom' trigger="legacy">
                  <PopoverBody className='text-center'>
                    chrome://flags/#enable-web-bluetooth-new-permissions-backend
                    <ProcessingButton color="link" className='p-0' onClick={async () => {
                      navigator.clipboard.writeText("chrome://flags/#enable-web-bluetooth-new-permissions-backend")
                      await sleep(100)
                    }}>
                      <FaCopy/>
                    </ProcessingButton>
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
          </>
        }
        ref={modalRef}
        fullscreen
      >
        <div className='flex-container'>
          <div className='nested-flex-container'>
            <PeripheralTable
              data={entries(registeredPeripherals)}
              getExtraProps={() => ({
                renderManagement: (id: string, registeredPeripheral: RegisteredPeripheral) => {
                  const connectedPeripheral = connectedPeriperhals[id]
                  const MaintenanceComponent = connectedPeripheral?.getMaintenanceComponent()
                  return (
                    <ButtonGroup className='w-100'>
                      {connectedPeripheral && <ProcessingButton block onClick={async () => {
                        connectedPeripheral?.disconnect()
                        setConnectedPeripherals(old => Immutable.del(old, id))
                      }}>
                        <Translation name="T.misc.disconnect"/>  
                      </ProcessingButton>}
  
                      {!connectedPeripheral && <ProcessingButton block onClick={async () => {
                        const Driver = PeripheralRegistry[registeredPeripheral.usage][registeredPeripheral.driverType]
                        const peripheralDriver = new Driver() as PeripheralPrinter
                        peripheralDriver.setConfig?.(registeredPeripheral.suppliedConfig)
                        await peripheralDriver.connect(registeredPeripheral.automaticReconnectionData)
                        setConnectedPeripherals(old => Immutable.set(old, id, peripheralDriver))
                      }}>
                        <Translation name="T.misc.connect"/>  
                      </ProcessingButton>}

                      {MaintenanceComponent && (
                        <SimpleModal
                          fullscreen
                          title={<Translation name="T.misc.maintenance"/>}
                          trigger={open => (
                            <Button color="info" onClick={open}>
                              <FaCogs/>
                            </Button>
                          )}
                        >
                          {<MaintenanceComponent device={connectedPeripheral}/>}
                        </SimpleModal>
                      )}

                      <ProcessingButton color='danger' onClick={() => {
                        connectedPeripheral?.disconnect()
                        setRegisteredPeripherals(old => Immutable.del(old, id))
                        setConnectedPeripherals(old => Immutable.del(old, id))
                      }}>
                        <FaTrash/>
                      </ProcessingButton>
                    </ButtonGroup>
                  )
                }
              })}
            />
          </div>
          <Button block color='primary'>
            <Translation name="T.misc.add"/>
          </Button>
        </div>
      </SimpleModal>
      {children}
    </Context.Provider>
  );
};
