import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { MaybePromise, useConstantRef } from 'component_utils/utils';
import Portal from 'components/Portal';
import './style.scss';

export type AutoCompleteEntry = { label: ReactNode; value: string };
export type AutoCompleteFetcher = (input: string) => MaybePromise<AutoCompleteEntry[]>;

export interface AutoCompleteRef {
  clearSearch: () => void;
  hasSelection: () => boolean;
}

interface Props {
  msBeforeSearch?: number;
  fetchAutocompleteOptions?: AutoCompleteFetcher;
  onSelect: (s: string) => any;
  onBlur?: (s: string) => any;
  children: (args: {
    ref: React.MutableRefObject<HTMLInputElement>;
    onKeyUpHandler: (e: React.KeyboardEvent<HTMLInputElement>) => boolean;
    onKeyDownHandler: (e: React.KeyboardEvent<HTMLInputElement>) => boolean;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => boolean;
    triggerSearch: () => any;
  }) => ReactElement;
}

const AutoComplete = React.forwardRef<AutoCompleteRef, Props>((p, outerRef) => {
  const ref = useRef<HTMLInputElement>();
  const constantProps = useConstantRef({ fetchAutocompleteOptions: p.fetchAutocompleteOptions });
  const autoCompleteContainerRef = useRef<HTMLDivElement>(null);
  const [autoCompleteEntries, setAutoCompleteEntries] = useState<AutoCompleteEntry[]>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const triggerAutocomplete = useMemo(() => {
    return debounce(async (s: string, forceSearch?: boolean) => {
      const fetchAutocompleteOptions = constantProps.current.fetchAutocompleteOptions;
      if (!fetchAutocompleteOptions) {
        return;
      }
      if (s.length < 1 && !forceSearch) {
        return;
      }

      const entries = await fetchAutocompleteOptions(s);
      setAutoCompleteEntries(entries);
    }, p.msBeforeSearch || 500);
  }, [p.msBeforeSearch, constantProps]);

  const clearSearch = useCallback(() => {
    triggerAutocomplete.cancel();
    setActiveIndex(0);
    setAutoCompleteEntries(null);
  }, [triggerAutocomplete, setAutoCompleteEntries]);

  const hasSelection = activeIndex > 0;
  useImperativeHandle(
    outerRef,
    () => ({
      clearSearch,
      hasSelection: () => hasSelection,
    }),
    [clearSearch, hasSelection],
  );

  useEffect(() => {
    const scrollableParents: HTMLElement[] = [];
    let current: HTMLElement = ref.current;
    while (current?.parentElement) {
      current = current?.parentElement;
      const { overflow, overflowX, overflowY } = window.getComputedStyle(current);
      if (/auto|scroll|overlay|hidden/.test(overflow + overflowY + overflowX)) {
        scrollableParents.push(current);
      }
    }

    const handleScroll = (e: any) => {
      clearSearch();
    };

    for (let parent of scrollableParents) {
      parent.addEventListener('scroll', handleScroll, { passive: true });
    }
    return () => {
      for (let parent of scrollableParents) {
        parent.removeEventListener('scroll', handleScroll);
      }
    };
  }, [clearSearch, autoCompleteContainerRef, ref]);

  return (
    <>
      {p.children({
        ref,
        onBlur: () => {
          p.onBlur?.(ref.current.value);
          clearSearch();
          return true;
        },
        triggerSearch: () => {
          clearSearch();
          triggerAutocomplete(ref.current.value, true);
        },
        onKeyDownHandler: (e) => {
          if (e.key === 'Enter' && !e.repeat) {
            clearSearch();
            if (activeIndex > 0) {
              const entry = autoCompleteEntries[activeIndex - 1].value;
              ref.current.value = entry;
              p.onSelect(entry);
              return true;
            } else {
              p.onSelect(ref.current.value);
              return true;
            }
          }
          if (e.key === 'ArrowUp') {
            const max = autoCompleteContainerRef.current.childNodes.length + 1;
            const newActiveIndex = (activeIndex - 1 + max) % max;
            if (newActiveIndex > 0) {
              const el = autoCompleteContainerRef.current.childNodes.item(newActiveIndex - 1);
              (el as HTMLDivElement).scrollIntoView(false);
            }
            setActiveIndex(newActiveIndex);
            return true;
          }
          if (e.key === 'ArrowDown') {
            const max = autoCompleteContainerRef.current.childNodes.length + 1;
            const newActiveIndex = (activeIndex + 1 + max) % max;
            if (newActiveIndex > 0) {
              const el = autoCompleteContainerRef.current.childNodes.item(newActiveIndex - 1);
              (el as HTMLDivElement).scrollIntoView(false);
            }
            setActiveIndex(newActiveIndex);
            return true;
          }
          return false;
        },
        onKeyUpHandler: (e) => {
          if (['ArrowDown', 'ArrowUp', 'ArrowRight', 'ArrowLeft'].includes(e.key)) {
            return true;
          }
          if (e.key === 'Escape') {
            clearSearch();
            return true;
          }
          clearSearch();
          triggerAutocomplete(ref.current.value);
          return true;
        },
      })}
      <Portal domId="app-body">
        <div
          unselectable="on"
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          className="autocomplete-suggestions"
          style={{
            display: autoCompleteEntries && autoCompleteEntries.length > 0 ? 'block' : 'none',
            top: ref.current?.getBoundingClientRect().bottom,
            left: ref.current?.getBoundingClientRect().left,
            width: ref.current?.getBoundingClientRect().width,
            zIndex: 1050,
          }}
          onClick={(e) => e.stopPropagation()}
          ref={autoCompleteContainerRef}
        >
          {(autoCompleteEntries || []).map((o, i) => (
            <button
              key={i}
              unselectable="on"
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              type="button"
              role="menuitem"
              className={classNames('dropdown-item', i + 1 === activeIndex && 'active')}
              tabIndex={-1}
              onClick={() => {
                ref.current.value = o.value;
                clearSearch();
                p.onSelect(o.value);
              }}
            >
              {o.label}
            </button>
          ))}
        </div>
      </Portal>
    </>
  );
});

export default AutoComplete;
